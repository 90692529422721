export var formJson = {
    title: "Get yourself registered to play the games!",
    logoWidth: 60,
    logoHeight: 60,
    showProgressBar: "bottom",
    showQuestionNumbers: "off",
    pages: [
      {
        title: "Tell us about yourself!",
        questions: [
            {
                name: "name",
                type: "text",
                title: "Tell us your name!",
                placeHolder: "FirstName LastName",
                isRequired: true,
              },
      
              {
                isRequired: true,
                name: "gender",
                title: "Select your gender",
                type: "radiogroup",
                choices: [
                  {
                    value: "male",
                    text: "Male",
                  },
                  {
                    value: "female",
                    text: "Female",
                  },
                  {value: "others",
                   text: "Prefer not to say",
                  },
                ],
                colCount: 0,
              },
              
              {
                startWithNewLine: true,
                name: "age",
                type: "text",
                inputType: "number",
                title: "What is your age?",
                //choices:["< 18","18–24", "25–30", "31–40", "40-50", "50+"],
                isRequired: true,
              },
        ]
    },
    ],
  };
  
  
  
  
  