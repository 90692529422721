const Games = [
   {
      id: "1",
      name: "Perfetti",
      status: "no",
      title: "Game Name",
      desc:
        "Test your knowledge of prices in this fun game. Can you beat the competition?",
      image: require("assets/img/games/number1.png"),
      lnk: "/games/price_it_right",
      
    },
    {
      id: "2",
      name: "Icici",
      status: "no",
      title: "Game Name",
      desc:
        "Are you the superwoman of Jamshedpur? Play this game and show he world.",
      image: require("assets/img/games/number2.png"),
      lnk: "/games/aytsoj",
      
    },
    {
      id: "3",
      name: "Pepsico",
      status: "no",
      title: "Game Name",
      desc:
        "Are you the superwoman of Jamshedpur? Play this game and show he world.",
      image: require("assets/img/games/number3.png"),
      lnk: "/games/hungry_runner",
      
    },
    {
      id: "4",
      name: "Bata",
      status: "no",
      title: "Game Name",
      desc: "Hope the communication works finally :p. Play the game to find out",
      image: require("assets/img/games/number4.png"),
      lnk: "/games/test",
      
    },
    {
      id: "5",
      name: "Zee",
      status: "no",
      title: "Game Name",
      desc: "Too much uncertainty? Now hold the reigns to your everyday life in this fun simulation.",
      image: require("assets/img/games/number5.jpg"),
      lnk: "/games/lifeSahiHai",
      
    },
    {
      id: "6",
      name: "Dummy",
      status: "no",
      title: "Game Name",
      desc: "Did Lockdown ruin your shopping spree?If yes, don't worry because we have Lockdown Diaries.Come and enjoy a game filled with fun, virtual shopping and many more surprises. Let the spree continue.",
      image: require("assets/img/games/number6.png"),
      lnk: "/gamespilot/lockdownDiaries",
      
    },
    
    
  ];
  
  export default Games;
  