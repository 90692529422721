import React, { Component } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import CardGame from "components/Cards/CardGame";
import FooterSmall from "components/Footers/FooterSmall.js";
import Games from "components/Arrays/Games2023";
import firebase from "./../utils/firebaseConfig";
import "firebase/database";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";


const database = firebase.database();
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class GamesStatus extends Component {

  constructor(props){
    super(props);
    this.state = {
     ticketID: null,
     results: [],
     games: [],
    }
    
    
  }
  

  componentDidMount = async() => {
    window.scrollTo(0, 0);

      this.urlParam = window.location.href.split('/');

      
    var ref = database.ref("2023Users/"+this.urlParam[4]+"/games");
   const range1_female = database.ref("icici/range1_female");
   console.log(range1_female.child("count"));
   sleep(10000);
    var snapshot = await ref
      .once("value");
    snapshot.forEach((child) => {
        

        var gameStore = Games[child.val().id];
        gameStore.status = child.val().status
        

        this.setState(prevState => ({
            results: [...prevState.results, gameStore]
          }));  

          
    });

    
    }
    
  

  
  render() {
    return (
      <>
        <Navbar transparent />
        <main>
          <section
            className="relative w-full h-full py-40 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/backgrounds/register_bg_2.png") +
                ")",
            }}
          >
           
                  <span
                    id="blackOverlay"
                    className="w-full h-full absolute opacity-50 bg-black"
                  ></span>
                
                <div
                  className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                  style={{ transform: "translateZ(0)" }}
                >
                  
                </div>
              </section>
              <section className="relative py-16 bg-gray-300">
                <div className="container mx-auto px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div className="px-6">
                      <div className="text-center mt-12">
                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                          GAMES
                        </h3>
                      </div>
                      <div className="mt-10 py-10 border-t border-gray-300 text-center">
                        <div className="flex flex-wrap justify-center"></div>
                      </div>
                      <div className="flex flex-wrap">
                        {this.state.results.map((info) => {
                          
                          return (
                            <CardGame
                              name={info.status}
                              key={info.id}
                              title={info.title}
                              //desc={info.status}
                              image={info.image}
                              //lnk={info.lnk}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  
                </div>


             
              
                 
              
           

            <FooterSmall absolute />
          </section>
        </main>
        </>
      
    );
}

}
  


export default GamesStatus;