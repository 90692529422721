import React, { Component } from "react";
import QRScanner from "../../components/QRScanner";
import { Button } from "@material-ui/core";

import { GoogleSpreadsheet } from 'google-spreadsheet';

import firebase from "../../utils/firebaseConfig";
import "firebase/database";

const database = firebase.database();


const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);


class BataStatusUpdate extends Component {
    constructor(props){
      super(props)
      this.state = {
       ticketIDs: [],
       rollNo: null,
       bata: 0,
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleXLChange = this.handleXLChange.bind(this);
      
    }

    

    componentDidMount = async () => {

      const ref = database.ref("BataCount");
      
      var snapshot = await ref
        .once("value");
      snapshot.forEach((child) => {
  
          this.setState({bata:child.val()});
      });
  
  
      
    }

    handleSubmit = async() => {
      
      database.ref("BataCount/count").set(this.state.bata+1);
      for(var i=0;i<1000;i++)
      {
        console.log("");
      }

      setTimeout(()=>window.location.reload(false) , 3000);

    }


    handleXLChange(event) {
      this.setState({xlRollNo: event.target.value});
    }


    handleChange(event) {
      this.setState({rollNo: event.target.value});
    }

    handleNext = () => {
      var ref = database.ref("2023Users/" + this.state.rollNo+"/games/Bata"+"/status");
      ref.set("Played");
      database.ref("BataCount/count").set(this.state.bata+1);
      
      setTimeout(()=>window.location.reload(false) , 3000);
    }
    

    handleID = (ticketID,string) => {
        //Update status in the database
        var ref = database.ref("2023Users/" + ticketID+"/games/Bata"+"/status");
        ref.set("Played");
        database.ref("BataCount/count").set(this.state.bata+1);
      
    }

   
    render(){
      
  
      return(
        <>
          
          <main>
        <section
          className="relative w-full h-full py-40 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/backgrounds/register_bg_2.png") +
              ")",
          }}
        >
         <span
                    id="blackOverlay"
                    className="w-full h-full absolute opacity-50 bg-black"
                  ></span>
                
                <div
                  className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-40-px"
                  style={{ transform: "translateZ(0)" }}
                >
                  
                </div>
              </section>
          
            
            <>
            <section className="relative py-16 bg-gray-300">
                <div className="container mx-auto px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div className="px-6">
                      <div className="text-center mt-12">
                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                          UPDATE STATUS OF GAME 4
                        </h3>
                      </div>

            <div class="flex flex-col justify-start items-center mt-16">
            <div class="flex-auto overflow-hidden mt-5 gap-y-4 bg-white border border-gray-200 rounded-lg drop-shadow-lg">
            <label>
                        <b>For XL Students only</b>(ROLL NO) :
                        <input type="text" value={this.state.xlRollNo} onChange={this.handleXLChange} />
                      </label>
                      <Button className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500" onClick={this.handleSubmit}> Next </Button>
<br></br>
<br></br>
            <label>
                        Enter Ticket ID and click Next :
                        <input type="text" value={this.state.rollNo} onChange={this.handleChange} />
                      </label>
                      <Button className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500" onClick={this.handleNext}> Next </Button>

                      <h1><b>OR</b></h1>

                      <h3>Scan QR on the Ticket</h3>
                        
              <QRScanner onScan={this.handleID}/>
            </div>
            </div>
            </div>
            
            
</div>
            </div>
            </section>
          
            </>
            
        </main>
            
        


    </>
      )
    }
  }
  
  export default BataStatusUpdate;