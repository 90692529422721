import React from "react";
import logoImage from "../../assets/img/icons/Games.PNG";
import { Link } from "react-router-dom";
import { userContext } from "utils/userContext";
import { useContext } from "react";
import {GameLanding} from "../../views/MaxiFair/GameLanding"
import { PropTypes } from "prop-types";



export const AuthNavbarPage = (props) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const user = useContext(userContext);

  AuthNavbarPage.propTypes = {
    btnState: PropTypes.func,
  };


  return (

  
  <div className="mt-0 w-full fixed mb-2 top-0 left-0 right-0 z-10 bg-white drop-shadow-lg">
    <div className="mx-auto max-w-full">
      <div className="flex items-center justify-between">
        <div className="w-full hidden lg:flex justify-between lg:w-auto lg:static lg:justify-start">
            <Link
              className="text-white place-self-start self-center text-sm font-bold leading-relaxed inline-block ml-8 sm:ml-16 py-2 uppercase"
              to="/"
            >
              <img className="max-w-xs scale-50 sm:scale-100"
                src={logoImage}
                alt="MAXI"
              ></img>
            </Link>
          </div>
        <ul className="items-center hidden space-x-8 lg:flex mr-8">

       
      <li>
            <a
              href="/GameLanding"
              aria-label="Home"
              title="Home"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-purple-400"
            >
              Home
            </a>
          </li>
        
          <li>
            <a
              href="/Faqs"
              aria-label="FAQs"
              title="FAQs"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-purple-400"
            >
              FAQs
            </a>
          </li>
          <li>
          <button type="button"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-purple-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none"
              onClick={() => props.btnState()}
            >
              Register to Play
            </button>
          </li>
        </ul>
        </div>
        <div className="lg:hidden">
        <div className="mx-auto flex justify-between items-center">
            <Link
              className="text-white place-self-start self-center text-sm font-bold leading-relaxed -ml-16 sm:ml-16 py-2 uppercase"
              to="/"
            >
              <img className="max-w-xs scale-50 sm:scale-100"
                src={logoImage}
                alt="MAXI"
              ></img>
            </Link>
        
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="p-2  transition duration-200 rounded place-self-end self-center focus:outline-none focus:shadow-outline hover:bg-purple-50 focus:bg-purple-50 mr-2 sm:mr-16"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
              </svg>
          </button>
          </div>
          
            
          {isMenuOpen && (
            <div className="relative top-0 left-0 w-full">
              <div className="p-5 bg-white border rounded shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <a
                        href="/"
                        aria-label="About Us"
                        title="About Us"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-purple-400"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        aria-label="FAQs"
                        title="FAQs"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-purple-400"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                    <button type="button"
                        className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-neutral-700 transition duration-200 rounded shadow-md bg-purple-700 hover:bg-purple-400 focus:shadow-outline focus:outline-none"
                        onClick={() => props.btnState()}
                      >
                        Register to Play
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
  );
  
};



export default AuthNavbarPage;