import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Navbar from "components/Navbars/AuthNavbarPage.js";
import Footer from "components/Footers/FooterPilot.js";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Component } from "react";
import { PropTypes } from "prop-types";
import { dbCreateUser } from "utils/firebaseDB";
import { backendURL } from './../utils/backendInfo';
import { exportComponentAsJPEG } from "utils/exportAsJpg";
import moment from "moment";
import { GamesPilot } from 'views/GamesPilot';
import Form from "views/auth/Form";
import { userContext } from "utils/userContext";
import AuthModal from "components/Modals/AuthModal";

const axios = require("axios");
const { customAlphabet } = require("nanoid");

const useStyles = (theme) => ({
  accordion: {
    width: "100%",
    paddingBottom: "100px",
  },
  accordionBody: {
    backgroundColor: "#E2E8F0",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
});

class Faqs extends Component {
  static get propTypes() {
    return {
      updateUserContext: PropTypes.any,
      classes: PropTypes.any,
    };
  }

  

  mobile = null;
  ticketRef = React.createRef();
  shareUrl = "https://maxi-xlri.in/fairTicket";
  shareTitle = "Join me online at the 42nd MAXI Fair on Jan 30-31 2021!";

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    };
    this.loginpopup = this.loginpopup.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  loginpopup(){
    this.setState({ showPopup: (!this.state.showPopup) });
  }

  onFormFill = (data) => {
    if (this.mobile) {
      data.mobile = this.mobile;
      const alphabet =
        "123456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz";
      const nanoid = customAlphabet(alphabet, 10);
      data.maxiId = "MAXI-" + nanoid(); //=> "dCok8DprjX"
      data.createdAt = moment().format("DD/MM/YYYY , HH:mm:ss");

      this.props.updateUserContext({
        ...data,
        isSignedIn: true,
        isFormFilled: true,
      });
      dbCreateUser(data);
      //send data to backend for newly filled form
      axios.post(backendURL + "/users", data).then(function () {});
    }
  };

  onSaveTicket = () => {
    window.scrollTo(0, 0);
    exportComponentAsJPEG(this.ticketRef, { fileName: "MaxiFairTicket.jpg" });
  };
  
  render() {
    const {classes} = this.props;
    return (
      <>
      <main>
      <userContext.Consumer>
              {(user) => {
                this.mobile = user.mobile;
                return (
                  <div>
                    {
                      user.isSignedIn ? (
                        user.isFormFilled ? (
                          <GamesPilot />
                        ) :(
                          <Form onFormFill={this.onFormFill} />
                        )
                      )
                        : (

          <>
      <Navbar btnState={this.loginpopup}/>
              {/* <ParticlesBg type="circle" bg={true} color="#58c3fc" /> */}
              <div className="fixed hidden inset-0 overflow-y-auto h-full w-full">
                { this.state.showPopup ? <AuthModal btnState={this.loginpopup}/> : null }
               </div>
        
          <section
            className="relative block h-500-px"
            style={{ height: "350px" }}
          >
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/backgrounds/bkg_purple.jpg") + ")",
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
              ></span>
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-300 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </section>

          <section className="relative py-16 bg-gray-300">
            <div className="container mx-auto px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                <div className="px-6">
                  <div className="text-center mt-12">
                    <h3 className="text-4xl font-semibold leading-normal text-gray-800 mb-2">
                      FAQs
                    </h3>
                  </div>
                  <div className="mt-10 py-10 border-t border-gray-300 text-center">
                    <div className="flex flex-wrap justify-center"></div>
                  </div>
                  <div className={classes.accordion}>
                    <Paper className={classes.accordionBody}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                          What is MAXI? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          The Marketing Association of XLRI, or MAXI as it is fondly known, is one of the oldest B-school committees. Started over 40 years ago, MAXI’s mandate is simple – popularize the field of Marketing at XLRI. 
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          What does MAXI do? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          MAXI promotes the idea of marketing through a mixture of competitive events, talks, interaction sessions, conferences and of course, the world-renowned MAXI Fair. MAXI facilitates live projects for interaction between the academia and the industry. Intra-college activities like MAXI Bazaar and industry interaction sessions under Perspectives serve to add flavor to the academic courses taught in Marketing and keep interest and enthusiasm high. 

                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          Is there an entry fee for the game? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          No, you do not need to pay anything to participate in the game.

                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          How do I take part in the game? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          You have to register for the game on the MAXI website by giving your contact information and enter the OTP. You will then be allowed to play the game. 
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          How do I win the game? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          Every game will have different rules and a criteria for completion. As soon as you meet the criteria and complete the game, you will win a prize.

                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          What are the prizes for winning the game? 
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          Once you have completed the game on the MAXI website, you are guaranteed to win a cash prize. 

                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          How will I receive the cash prize I have won? 

                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          Using the contact details you provide during registration, we will transfer the winning amount to your paytm number/bank account. 

                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.heading}>
                          How can I share this game with my friends? 


                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                          You can send the link to the game directly to your friends through social media and Whatsapp after registration.


                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </>
                          
                          )}
        </div>
        );
      }}
            </userContext.Consumer>
        <Footer />
        </main>
      </>
    );
  }
}

export default withStyles(useStyles)(Faqs);
