export var OTformJson = {
    title: "Get yourself registered to track your ticket sales!",
    logoWidth: 60,
    logoHeight: 60,
    showProgressBar: "bottom",
    showQuestionNumbers: "off",
    pages: [
      {
        title: "Tell us about yourself!",
        questions: [
            {
                name: "name",
                type: "text",
                title: "Tell us your name!",
                placeHolder: "FirstName LastName",
                isRequired: true,
              },
            
              {
                name: "rollno",
                type: "text",
                title: "Enter your XL Roll No",
                placeHolder: "BJ22xxx/H22xxx",
                isRequired: true,
              },
      
              
            ],
          },
          
    ],
  };
  
  
  
  
  