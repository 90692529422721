import React, { Component } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import CardGame from "components/Cards/CardGame";
import FooterSmall from "components/Footers/FooterSmall.js";
import Games from "components/Arrays/Games2023";
import firebase from "./../utils/firebaseConfig";
import "firebase/database";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { listOfEligibleGamesForUser } from "./../utils/profilingLogic";
import { SelectPicker } from "rsuite";

//MASTER SHEET
const SPREADSHEET_ID = "1YMEV2Ee64ljZvzqi4WPPfdkagCYTUZA7Fop7PuCZB0k";
const SHEET_ID = 0;
const CLIENT_EMAIL = "googlespreadsheet@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY =
  "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCsyMaeWVif1DsG\nZVwUVJih6IFKlPQF4DWBwlB/URQPWUi4X7dpB7eaWezf93F+Hbsrnxz9rSRXLr8W\nmGQVhlJ1yCR+7uKmr6Qk1X5SVKWzUoEgqOyu+8DHC/YWT/Ns+1LYR0dy5HjGZAV9\nlOAJkTekEy/gOX3IscAWRMfbhuaUBAez2EWJsbTXU4yO6AcDJyP7pvOL4HvUNrBo\nc1r7izBwPf3HUx9r0eoCNzKDDfIlKCuaZOa2JTKU353X4NRUN06m3I4GGyDhr04x\nBC0/nvxzHlcnLL8vmYKqCS+6+o+qh6nWhePFWLr2R+69XgbnWiKwBf1JdpKWwz7L\naVH34QdDAgMBAAECggEAH8z8MVzEYPZQvsadAejoBrSiRzXPC93OO0mmQyiLBqAq\nMDT9rskK08/FNxt1uf6C2m54iX/6dklWRjRaB1QnjSH/BZW2BXPCRD0++Kw3qx9+\nQKKWnUYcnwXmjwi8Bszi3IeR8OV4DRuPnyNtcJVXpk0ZvK0YJ33/mpmiJxdXDNq7\nkj2szSUYhi7xRAHW+pBFphb3pLC3kIqYOOpGzUtwlB6cBiTjx1uLwQorRYjOjUNa\nTN0Kr8GrEQJYOHQBB3CFDNfl/xicfCMyNkaT31U/wYHkJwFEC+IqqHiue59yKATw\n6K633WC/9rxT4dE2Qe+vrW7c5r5ddMS+lfA/OXHBEQKBgQDZbYAPbod5VpiI7KL3\nG+r/rd+e4KiT3WcbX5OYxe2Rctim1fQGYGRLjm/JWOmRNrczHhJoyLExvFL8Mrwt\nJylQWTeGsP/JxphEn4OK1UecJlxdH/RTC+Vn21FLxi/o/uryNJ9sMYpMmaCNq4aK\n97H44QRKLX/LsJ6o1p0GLdP41wKBgQDLb8oIdTKvvS/XTHXQLmSBNqG6YUR3EeOP\nBH3Lz8eE7767D2ehoo4cKzzw4AcA1EDYOJcYrHVt1hmSr8ruKOeGkqTXTaplkIGS\npF/yY1ssXuPmOo01vXF+SmGo3PANRudczZYlJH8Ril0Kbnso16IEQus85tXgQioe\nHOSi2vd7dQKBgF6UP0x3K/8gKCIHrawIPOUTYV7fIvWPLba+kJm4H2tRpeSM1SWg\n8H/dacsQHinIv1BBGIj0JAAah46lEy4hBtuos3qnq9y9wqa3LKVLuWa3WR45NDKb\nziKrSGXJxDV6DgFl2qNZuWswuKJnppL0xDoax57sw9Z68clH8e4qKTbpAoGAQKNB\nRxStySbMR5UUrdO/vUp1cnmrPkXhzrQ3oAVhuHxQVrEbVUEH9C1MzJ/xubK287xv\nTbimCwMG1xOh37ABeEGpvHsB1AlpEgXGMoadFmkD8kfSTCvoHp9Vjki8wQsxQcxU\nk3KYKSOUUlcuLJju8nmssooJlJqRNUYq4w+9qG0CgYBk0DpLtCLophlaf8WAMlsE\nkltzqr80rlxqymAnOKkFvAg0WqW26GtHtAvIGt+ZR0Mw7MTnkfqoSroPShAGay7o\noTDwLjvogECWZ6/9BKHOiay2pT1GtJIe8sihK86Ga6gq8FCQGOgxMoSE2aPgeysq\nLJoD7pOn93nIJpYh8dXvMQ==\n-----END PRIVATE KEY-----\n";

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const appendSpreadsheet = async (row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

//Perfetti
const SPREADSHEET_ID_Perfetti = "1B-p4CJV75d0iBHOqRBpPQAqogIOTKj-WpXMurbUX-H4";
const SHEET_ID_Perfetti = 0;
const CLIENT_EMAIL_Perfetti = "perfetti@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY_Perfetti =
  "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQChxy2Gm9gvZx1O\nrytiLb1lEjrwNyrf+4hApBkcc6o1D5QG8d4ACBDg5EdPbVPSpt/MtDpdyG+PfI1Q\njLphKfeuHn2xvYucwXMMdj5bZXpspaWpbGjjnDp0Q7ZCoVj/D1DKPbDk0TC+dJU/\nWvxXwLpX2I2laQ9a+rJjcv+A9h7AFT+3zd5UPilsl/s9Da2PmiVaklQR52CkqSXY\npnbd1BUvFzGxXoE/NtDWpPhsAUS2gRFCjzC1MVuar84H2U1I592R7EqejVT/2Es3\n5zveXOh2lfUyqr25dnB3fSFiPwPyi8F+O0zAEHooqV62VW8B64mUgXU1EAqgQAaG\nEMSnstJbAgMBAAECggEAAxjDc9Su3jVsl7F9tJroibNAIpIDxZbUhFw/yM4j/3EF\nZd6iX8DHxe5IuSoAFUOxfAMlLN9l10rMj7Kp7Ks3fQNDZ7AXqJABakcnKWu3XLYN\nBkG3fkfizI/xQLRC9lruMnjc1nx0oOitZLGSrAFZ782K5JQuwZCT8phkcK0FaKrX\nqF2CKpf/e0w78YtNplKFCV738CxtcY71JEmYNGP0W2G7he2KVzQ81EKhIMw3N8d5\nL4vsAneZ1vvnBbVIf14+zGfF+CsFzYSaNCDi9hX7WUjiVAzSe1iwDZCP2io3fvb4\ndZ2S8rSDugqWcOqhe/Uv8tOYlaSH/60/VVDwpevtoQKBgQDW/aThyyheR/bBKmCP\nmHJBYTWRYEBZ1V06i57fwCO1fvRmxs7AJgQmUpSGdy3wd4ilO/RfQM1ZGSGviOiF\nW4+vZggtoy9vG7v9cYXarSJFt6AdZvW07eojLLmoO2T0I77rCBzLhsdByPnV02ix\nZjfAwYUTPCOn4912siuMGXsGoQKBgQDAoxEat1/al3l8pLyWImIT86XAufHK62Au\ny+j7assOTKLC0qxExdI9CQVyyZhz+qvnlVOWgJytZLuFuvm3xmuidTzoPjMm+sf6\nxnbJyAuCjR7wZidoDyk/q5GywQJU8u0ylFBiwtVtUTwjq01ix/MQWKO8BLxMp4Um\nG9/sKUrDewKBgFozAMJa4gzsVHKmvOyCIS2dA0SDBXyrL/ooKffmPRUgtpmIP6YO\nTR9m9jmUNwn0nLf2d4ifP4hqKt/iqOuKhrYoMLY1COms0Sp8PpSInyBw8hRj5iKK\nzvkDAsG6aZikhq1FMg+R9Hd9QpSbpgkHwcx1ZMe1K6B80aXDCtI0vpEBAoGACLub\nu+VSQBub0024iunWHmduPTVww601+Akgb71gpS5ONpzMWHXLWR7+kzyWX7OedWos\nlucPs0Ccy6w1Q+KH4J6F+1VOhlbaGAFFQ1KBSxLQsz8+TBZb3VoOyda8H1O/8woM\nNt30RPdozYkngfHkj/okuhJQ4vy5KYgb953LmN8CgYBq4P9k84iYIT8B3WIZ6SVx\niaBjv9d+6ul+wvXXRKZEbkxO6j7tzasMcXjgx9uyEBcZzhCGsCDYww4rzzAfO4eq\n5r4smzDXCr5Kprvvqn4jHSQPTqcsETgbX2zgsMgoi6jToF+gWHS2V6s8mhUAP6L0\nMGCEpeWAowf1nsmuelch3A==\n-----END PRIVATE KEY-----\n";
const doc_Perfetti = new GoogleSpreadsheet(SPREADSHEET_ID_Perfetti);

const appendSpreadsheet_Perfetti = async (row) => {
  try {
    await doc_Perfetti.useServiceAccountAuth({
      client_email: CLIENT_EMAIL_Perfetti,
      private_key: PRIVATE_KEY_Perfetti,
    });
    // loads document properties and worksheets
    await doc_Perfetti.loadInfo();

    const sheet = doc_Perfetti.sheetsById[SHEET_ID_Perfetti];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

//Bata
const SPREADSHEET_ID_Bata = "1-7UsoiSzp9qQTZqI1be39dY9nXl95ASE4ED6Q0skuAA";
const SHEET_ID_Bata = 0;
const CLIENT_EMAIL_Bata = "bata-677@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY_Bata =
  "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCqQCfHzGE4KGGl\nn8o9S08jfKWKCakjNrRhXOykFD+Wg8rhIT2VCMLSeVQnh4AzJZMCEDMvoI5prTZu\nyuGUienCOG3+oX63ZdN/TO/WE4oD6rj9jpsAR5wOolBfXtDSe2Tq5JSzHQolXaM3\n9sWLZevswLwsDYRxFTwaVz/0NS7DRKhFarGWn3K0eeCE0ICA7ghF7jcvsldqqG9g\nA9BXFHfLUHnzdR7ILTnPYzhjpvxyREAtDXKsI+igbCNKHgTV2TZvh6d3dLM6WOte\ndyHP71uf2OlV7Izl0sGwSvBA0zCwock007tKsxSyNpxIdNB1GlDGUth2Y72HvHpm\n81HDZcClAgMBAAECggEALnwR4IW3TcQI1VYJvC6hzwirkkMNAWZgXe0nV62HpysY\n69tNmzGnMByck6Z1pIFv4DJYJ3rpJ4V5xUpzyUDWqIcL0Wh61sxgsPNq0MLI/kTK\necDEJZFn1L8Qvtl+ntm+NjxvNXCKYqVseU1RprwPbLc7KbQPI2ku3epQtfcHa50S\nfJ8MtHxaE3XeuWay8veF8ea2gqbGFyAmg6M+miu76flrKP7K8ACeI4H0pdKZeDmo\nr83eGhcra0QhMqdcUuwYwfadsv4PLBlrGTPoQJrRYCL0jA3FjxAos+Iuo1FXLOt9\ncAmrYe4x3+s8b15OTJErMGT34vKp4S2JWSY5mJKlCQKBgQDtQPKHZkuCKcWwULa0\n2HMzIIuATyesYnoljre1opiJhBnYjlPqVgtMC0sS0wE4gIfHxf9U5xLnDNj/vDzm\nDObaQXebv+vE4WW77/lK/3G/KFNWbVYrq1C3DB40zPhc94qXhv6eyBv0qEF4Tlmk\nQPKiKZTcy8s3a7JTqxX12WZ7NwKBgQC3s+boEiecMYv0svM/t41VBogMYbXy6LtT\nooTBJ6V5vejQzP7QJWYj17ixFbyxyrh65kn51SDzWX83txLzNBFLlWSaEmm9lreC\nmb1sxHyNhGvl9R2GNpA8p/e0emhPnQMETj/Yj2LoySIkxP1rgv3EgeajVuUjMmUM\nDoiYyn2pAwKBgEzVg7Wlwz5cGNGI0iKvFl+wYn0jJU4GdlhDZ+tCxPOD/HrNXQ++\n3dm6ro1iGDscJNzlmlL8onqBBqWLaI14ECsOmJWwCcKw3CmJBcrr/wJRJOwQbJ+n\n03P0ULP4zVYONKvt4/e4BpWtB8bWWOGAxxMNNFBtD1rbXd0KwwnjZfTxAoGBAK7R\n5cy0ej2KaUZVhmf8peNkc9RUqHKiSKMu5mdq828H66fJbjVnYex6PqlHpmsMIMPU\npFG9Ib50TT0YXFBWHJ9xbGuzWi2yYLFI3ZvE4UNL4eLVgWNYSatvratzUhFxuo0+\nakQNMHOX9YZIcWL/JcD8XiDTcPwt2TvAGP5OtYdBAoGBAM72Zmm8OoX9/T3kunTO\n6zkmrYD/AO6sbSuwRpPk0shCofQ4j52pEZ1QpfjBsMUiRnACECCCeqsakZBUlgNm\nMAowL536442rnZGt2fUvUWoJqRQEz044jSosYrg5MTr8OcXTy2zSB90JR9uAEbbB\nYbKLLUhiGcPZIrSNsoVXQdYh\n-----END PRIVATE KEY-----\n";

const doc_Bata = new GoogleSpreadsheet(SPREADSHEET_ID_Bata);

const appendSpreadsheet_Bata = async (row) => {
  try {
    await doc_Bata.useServiceAccountAuth({
      client_email: CLIENT_EMAIL_Bata,
      private_key: PRIVATE_KEY_Bata,
    });
    // loads document properties and worksheets
    await doc_Bata.loadInfo();

    const sheet = doc_Bata.sheetsById[SHEET_ID_Bata];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

//Pepsico
const SPREADSHEET_ID_Pepsico = "14-QD4kmBSniKwPlrA2kiVMSDImW7MhgsEaYFiOZM2E0";
const SHEET_ID_Pepsico = 0;
const CLIENT_EMAIL_Pepsico = "pepsico@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY_Pepsico =
  "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDJ49Hv9PB6vgQH\nPuyJGYjB4Cqko111xMCcfbL/ONzR9RJ2RgLKobvGRGqzf73cEoaq4drspvJrf5xY\ngHy3uS0/ZCxnZDFiP1NxXDYI/bOUvdoQny8Y/fps2uWgXv3vPAVj2QZ6PIRFiZAJ\nggdGjMPYLT4J6ClM+1EyZbzp3VOE6nnOLAkZcJOTMPxpaxzmSVmTKJTgSx/pTUzb\nla9jA9/Iae4nrMQJow86247r1uSSzBDflU8lWownD80LUZXnEbddVB3XcaKAjz3X\nWlB0PrEq6Mm1CrOZZ6eiOsL5buHG0lC2z4Gb2sMv4zlaSzKddgsZG0+yDuzCxiFl\nLKvUsACjAgMBAAECggEAXIJF2lw0LSoQknlkzVWj8qKTJ6mr06D6WTrH4Gbf2F0h\nvoxKDyuY7TrAYcuw5byfdIHVXQcKofNxYqRNgaoab6epKR8h+dDmnVURdYE63nc4\nCtHGNP971pFfEuM0HIE2PxBdBXnhajOkipbIWq15t/7TJJr7AclO5Xb033yJHqe5\nuS0dXZP7/LIdO/C2kC6OHFdltWCVUWeHHtLsoCAkp+8hRo/b04dQDK1zBUHa8VXj\nyrFKmgQl3OqlqfrrAV8m6ilAxMPc1KIhwDMqm2FE5aQ5rmFU/pBF4wg4zqdBrN8R\nPvdTMnjxVSU+oR06tKKhR1q9Tp45gHf7zCzjnheQIQKBgQDxogn9Z01maxYSlbtz\nE5mRWC2AtBHkyY8QtA6SJfgpX52HNsdZVN4NCXudxQbBXnHgSgvoQ6lUHonMRTDd\nZpZOxg+Ane5C7XpoSA1EI/MilLSFXO6xrsqzikqbk3U6TJHW42UWZjuu9q7uMLwU\nKKe7H2To1sINBcS9BHAa36zvmQKBgQDV5Ndl2Stl5Fld4hfzmynfOQHY2bwqhIzf\nMmeyS1gFHBgTNoRiVwnBl4pUdaj5DDXdTw+5i0dGCfxBFGopfLrUqD99yPaZHN5B\nT35U6ADyC+Na8mH8BKdzxFfIaE96q4k1Y23PbcgaHzLdBM0hRUb5KK8dr2bJ0tmy\nxUoUb3PHmwKBgQDmZwc1jPodzcCHSiUqTqchEz6YOZvSV2tGlxpJu1DNSrHvy4j8\nolL1hXRM9SFC4yacop6GA3aDknb8w4EbrAVo6azAjFczlDQjGYkkz064S6lyfTHR\nVYVM+3G2tT2TsI1jpQKwAB2q8OfOkcPVSQre/8l9x8HTHwwA3Cl1RpElCQKBgQCc\noCQyimJDDIF8Q/FyCy8QLHhzyI0HPWTCk/BnGNDtfPMMLqGmOUIFpug+jKIdLJnM\n4vYK0jhwyyMSrNZIZvOQgVtsyln5KLB/dqx1lCYNGDN2B4NEvku4dBArymZY139z\nvslMWYFrz2i5e6iSMl6UWPxSanyEUYfkZp+mcy5+/QKBgFAlr7DN/C7CP+YafwKP\nkafhYaAg9udIp65sCzutMyxnseTIlXV4Gi+MO6wxHdex93lSYYRM14jYheiOvuks\nUIDG5quOpkg4Yv7JpMpYd9qQBz7Nv3zCDaID8Co5iS6Hdgx+f9j61MDTwX6X7WX9\noHOVwzcmdjl8t8ypp0FOx2xs\n-----END PRIVATE KEY-----\n";

const doc_Pepsico = new GoogleSpreadsheet(SPREADSHEET_ID_Pepsico);

const appendSpreadsheet_Pepsico = async (row) => {
  try {
    await doc_Pepsico.useServiceAccountAuth({
      client_email: CLIENT_EMAIL_Pepsico,
      private_key: PRIVATE_KEY_Pepsico,
    });
    // loads document properties and worksheets
    await doc_Pepsico.loadInfo();

    const sheet = doc_Pepsico.sheetsById[SHEET_ID_Pepsico];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

//Zee
const SPREADSHEET_ID_Zee = "1vb3DGXWCfJb27dD5-IW1nrCoB6JsOnZfsaYYIOr9fwQ";
const SHEET_ID_Zee = 0;
const CLIENT_EMAIL_Zee = "zee-598@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY_Zee =
  "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC1CLmWcMXLijtY\ndNoZr/RntOkmPtJzTiqsWgy+HPnxvXM9T31xPd5O1qS850+fwqHRJ1BvK92M+ssP\nJgYJ8pJpw3aqKw7wkDfhI4l9vIKNeuYcSjhDTuuxh9AKdXIKcoCf9mKVR9KMDPIV\nRbf43Z7Pv2a9I8/3+/H5fcbz5Inha7tvqMPAzNe4BNWqf/M4gUXFxMPZSO2W9qGJ\nfvd7H5SzN551PmlMy0+Z/hc0RV6M3ocO5Oj2GxMlOQ1BuBWEIp9G4CJrMO02IenY\nM6UGsXSFj0jwlc/8yBMAYm40trWyYvG3qrq0i77u/Dq1AG2zTUhkOdPMX4+m1GdV\nT1bnXaJ7AgMBAAECggEAMhBzopMVYDCeMNw/5JKweG2jfJwq/xBC5N8SXxEMXjoS\nQIZXHpYJgKwDaupdLsVYksrYh6YN4wsIVOwjB2U58F3J102JGCYKDg0R3N/hCtdO\nA8zSump869+vPPU10IXR5Gry4G24YFrEJ/Xb5ssDPFimRPA+d0oJpQGPn6s4Jkp4\nfB3CrxRZcQMwmzZxSyWyzRLDJ1XpIy/S+QAABU7HZFhMrBFV3hDwG6Za0l/N/FfA\n68JcIZVVeX/dBuCXKRiIixUi5B2XGrlLnOhKMtljqT6wa4ZDmfW7X/Sj25q40yob\n73Jh7HK5LGiv/dkQsrnZRZVqY6zNUvIA+WUWVmZfwQKBgQD44lcwv1EMIs1NVc5r\nkYPbybkeb4vSuTMhhTcbV9VW/2+LIjCiglOp4g4o/oQ09g37PEb8PcWku+FZfjLF\nlD+dMXf2yP4v/W7CA5LEbHbFnpnSVeoksdo+yVWp7gVjVqBeLZa9tLpkF3kJJ1q6\nT6x/w7Ph2l448Mi4e7RrMR6iAwKBgQC6NcTZu0Sk6vl5GTZ3ahj1VIB7+BpSna3x\ndNexkzuZBKMNyEMkMXuvQjawjvymylFY+trVN4ze6zdnu6T60yH7bA2+O4gQjdbU\nk1DxZSAXyOygNpJenpYQ7agWQPMjRH9MRXRp8nmIDchJeS3xJXFEe3pc8LeXsHYv\nnwrZshyQKQKBgQDJlbp9V1hz9fRHwUpSQiiE6tyv1DVyVmMdsWKzGO9JadeU3/p4\nhgrxvJEvjwbSRsGITohIYlglV6/s5pSFB9CGp7y8/Is1n5YIOxCzXA7PfeilPsVJ\nN2zIEhb6bjma/l0iKta/jRSzYXe9fEy2PzY+veqC8nYCtLQ/CKNXdw+StQKBgBW6\nNmjtd/oCTO+7aGaQo67kmEVe0rt3GJfG+XAH7PlS2C/AgU0U1QjDIhyB4qsXilxm\nkOg2HiLYsVNSStLVle5/Ui8zRRIhasLNGbHBCficgjfDhlqyCHKbOHS4SWaBFJVy\nuT2vhEzCxu8Dy7+KjefevI1hrRpvBatS1HJIXRrZAoGAYdrTpVrFQmQmNFI1tksA\nNl5AyoL3JccxIcBB/TF+GsrgGMyub6jkkgjiEnB5o3GUgw/w5JHlfrG3ihez09Qc\nLikPp038Y7flddjc1Du/iAI+WugiznG3taf4ARIrO4fo9CDKS+jop8RXmaay8opn\nsKts26/Ib64W+E3hNqUMHX4=\n-----END PRIVATE KEY-----\n";

const doc_Zee = new GoogleSpreadsheet(SPREADSHEET_ID_Zee);

const appendSpreadsheet_Zee = async (row) => {
  try {
    await doc_Zee.useServiceAccountAuth({
      client_email: CLIENT_EMAIL_Zee,
      private_key: PRIVATE_KEY_Zee,
    });
    // loads document properties and worksheets
    await doc_Zee.loadInfo();

    const sheet = doc_Zee.sheetsById[SHEET_ID_Zee];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

//ICICI
const SPREADSHEET_ID_Icici = "1BSJfxhY66diH9FKGk35OCHovBu4oz5PqDlka2QpK08o";
const SHEET_ID_Icici = 0;
const CLIENT_EMAIL_Icici = "icici-179@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY_Icici =
  "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCUNe3A62ohiP47\na+UFvenGok+2zNDPj0Tc62XudrvO/OU/KUNhZBei7ErAGrBdTzjwNk4selwkqAj4\nuDGJEx/AbPx7gtEeMz9k9EyStFRvy0nhHLzKTkocxvsuJ9u8rugwIBbQm4wWAJju\ncQ63Rfjm3qilYjKbCUfDdQ8YJMsdh58FG1bJbY2m7gJyw/oy7OiDhflcizB+ikOO\nfjDvyXjv99eMQKjNHRSAwq8/DSE8prpL6rSJBveCE2D9my59UcaMVPja2BpUlfcd\nkKP/wHk37JSRAtDfYNz6hFXQGdgC70sQkFpA+irPx0uSLicqdH7J9yF7NwT6yghc\nRbXEhlThAgMBAAECggEAA7SXdZ/NHvqEVKr9ZlfZrOgo8wqK2+2ajKVr/nrEdkfX\nWW1oLFqqxp1+vu/L4gFVjJfm+3Cxtg7nboR4ZJNwU0jGRTY/kTA50QXUlkRg6gyc\nk766r5omezK0W/KOKVj8IBvlRiDlbzVjnW6BLi7XKrp9X+klF8AzJZ1bG8DrvRi5\nr66XUo31K7SzyILOqgd+mXHC+3BcZrVwyennE3l5FjSA4PEZ71G4f2XlHeMR1P1D\nhgbvBXtBZ8fN3LKAS6VpA635pgTLytzjiIp8sKyZ7pgTLGGN7xEw8AyN3c1s8ItE\nSXJRu+hdrgpKqh3mDIInNufOdG+R2TRX/7MDJeDJ4QKBgQDJkED4Gl36TQwyj5CR\nRkfM++YdZXmfoFNk/OP0Mo2exQbw8hN5BrWXafZZASfoQ8OrQWZ4F3ygYAcQp8PB\nrBUiKIuJ+MnwsnTT6jyy6O6jMTMQEvnxu8hF/9r7CbOQCjnXCe1Ti59GYopT1xhv\n0dnNfSzOGGilw+jGHlzvRrLfaQKBgQC8PPQmGh6yGtvgammtEh4sfc2SMDLyveWz\n8BkbKqwt9oIc+4R3WHb8LBBMlsRgueg2ckRTgJMHhdpfaYyWRrKUIHLnHGt3uo2B\nqKlKgvh9LEynfz+9r0FoJckBqYcNxtX6SAHCAVm08bwJjnfjL6NAQDWvS63AWmUE\nDSkXmPGSuQKBgANUse4nqWPogAoZ2z67VZZqlzvnIGJl6NrjXvT7HOIByYKTzYST\nes68vohr/3fUHtimpQXZBYM08wjwbMi3ikXdO6kVBmlG2a1i0ov2U3M47eoXFjBL\ndRzLBJhfaas4sAoArP5TcVdda4HFLS8z3ZCKwAHL33bVzPPxLoH5vhz5AoGBALNI\nTrUXfZLet/qG/31iWzC+5RNOKsg9i1CU5iSUwQbJ2OTnvSO6MyE8io2XrxP9HKj2\nrhLly6OHnhPvwIHEdgNU4Hsz+zs+h9YtIIgiM69siNmi/b+Y8u/RxR97KnE5psVI\n3bu8c4c+EXz8nzzXO2JPhZIevy8MKVfxfOjHt5WpAoGBAJBsa3eBc86BBAzDmju+\nOJXY7qeX3Va3KodrEWC728nX1J1ErtFCgxYmN9DfUMi+TX7yc6DDSWUkxBcJT9Dl\ncPBDYR2ifnUwj0JCV1tzALvq0mI0kBvOGI9GJRZZuA0w3OGEfDy0B+QymPJOPrUj\nZ0mroBBMjvdLURnc8hxnnD3f\n-----END PRIVATE KEY-----\n";

const doc_Icici = new GoogleSpreadsheet(SPREADSHEET_ID_Icici);

const appendSpreadsheet_Icici = async (row) => {
  try {
    await doc_Icici.useServiceAccountAuth({
      client_email: CLIENT_EMAIL_Icici,
      private_key: PRIVATE_KEY_Icici,
    });
    // loads document properties and worksheets
    await doc_Icici.loadInfo();

    const sheet = doc_Icici.sheetsById[SHEET_ID_Icici];
    const result = await sheet.addRow(row);
  } catch (e) {
    console.error("Error: ", e);
  }
};

const database = firebase.database();

class GamesDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketID: null,
      results: [],
      games: [],
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    this.urlParam = window.location.href.split("/");

      let ref = database.ref("2023Users/" + this.urlParam[4]);
      let data = await ref.once("value");
      let row;
      let row2;
      data.forEach((child) => {
            row = { Name: this.urlParam[6].replaceAll('%20',' '), Email: this.urlParam[8], Gender: this.urlParam[7], Contact: this.urlParam[5], Age: this.urlParam[9], TicketID: this.urlParam[4] };
            row2= {Name: this.urlParam[6].replaceAll('%20',' '), Gender: this.urlParam[7], Age: this.urlParam[9], TicketID: this.urlParam[4] };

      })
      
      ref = database.ref("2023Users/"+this.urlParam[4]+"/games");
    
    var snapshot = await ref
      .once("value");
    snapshot.forEach((child) => {
        if(child.val().name=="Perfetti")
    {
      appendSpreadsheet_Perfetti(row2);
    } 
    else if(child.val().name=="Bata")
    {
      appendSpreadsheet_Bata(row);
    }
    else if(child.val().name=="Pepsico")
    {
      appendSpreadsheet_Pepsico(row);
    }
    else if(child.val().name=="Zee")
    {
      appendSpreadsheet_Zee(row);
    }
    else if(child.val().name=="Icici")
    {
      appendSpreadsheet_Icici(row);
    }
    else
      console.log("Dummy");

        var gameStore = Games[child.val().id];
        gameStore.status = child.val().status
        

        this.setState(prevState => ({
            results: [...prevState.results, gameStore]
          }));  

          this.setState(prevState => ({
            games: [...prevState.games, gameStore.name]
          }));  
    });

    ref = database.ref("2023Users/" + this.urlParam[4] + "/games");

    var snapshot = await ref.once("value");
    var count = 0;
    snapshot.forEach((child) => {
      console.log(child.val());
      count++;
    });
    if (count >= 4) {
      var pepsiCount = 0;
      var zeeCount = 0;
      var bataCount = 0;
      var iciciCount = 0;
      var refx = database.ref("BataCount/");

      var snapshot1 = await refx.once("value");
      snapshot1.forEach((child) => {
        bataCount = child.val();
        console.log(child.val());
      });
      var refPep = database.ref("PepsicoCount/");

      var snapshot2 = await refPep.once("value");
      snapshot2.forEach((child) => {
        pepsiCount = child.val();
        console.log(child.val());
      });
      var refIcici = database.ref("IciciCount/");

      var snapshot3 = await refIcici.once("value");
      snapshot3.forEach((child) => {
        iciciCount = child.val();
        console.log(child.val());
      });
      var refZee = database.ref("ZeeCount/");

      var snapshot4 = await refZee.once("value");
      snapshot4.forEach((child) => {
        zeeCount = child.val();
        console.log(child.val());
      });
      var countArray = [];
      countArray.push(zeeCount);
      countArray.push(pepsiCount);
      countArray.push(iciciCount);
      countArray.push(bataCount);
      var itemsArray = ["Zee","Pepsico","Icici","Bata"];
      
itemsArray.sort(function(a, b){  
  return countArray.indexOf(a) - countArray.indexOf(b);
});
var removed=itemsArray.pop();
console.log("2023Users/" + this.urlParam[4] + "/games/" + removed);
database.ref("2023Users/" + this.urlParam[4] + "/games/"+removed).remove();
 let refm = database.ref("2023Users/" + this.urlParam[4] + "/games");
  var snapshotp = await refm.once("value");
  var count = 0;
  snapshotp.forEach((child) => {
    console.log(child.val());
    count++;
  });
    snapshotp.forEach((child) => {
      if (child.val().name == "Perfetti") {
        appendSpreadsheet_Perfetti(row);
      } else if (child.val().name == "Bata") {
        appendSpreadsheet_Bata(row);
      } else if (child.val().name == "Pepsico") {
        appendSpreadsheet_Pepsico(row);
      } else if (child.val().name == "Zee") {
        appendSpreadsheet_Zee(row);
      } else if (child.val().name == "Icici") {
        appendSpreadsheet_Icici(row);
      } else console.log("Dummy");

      var gameStore = Games[child.val().id];
      gameStore.status = child.val().status;

      this.setState((prevState) => ({
        results: [...prevState.results, gameStore],
      }));

      this.setState((prevState) => ({
        games: [...prevState.games, gameStore.name],
      }));
    });

    const newRow = {
      Name: this.urlParam[6].replaceAll("%20", " "),
      Email: this.urlParam[8],
      Gender: this.urlParam[7],
      Contact: this.urlParam[5],
      Age: this.urlParam[9],
      Games: this.state.games.toString(),
      TicketID: this.urlParam[4],
    };
    appendSpreadsheet(newRow);
  }
};

  render() {
    return (
      <>
        <Navbar transparent />
        <main>
          <section
            className="relative w-full h-full py-40 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/backgrounds/register_bg_2.png") +
                ")",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>

            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            ></div>
          </section>
          <section className="relative py-16 bg-gray-300">
            <div className="container mx-auto px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                <div className="px-6">
                  <div className="text-center mt-12">
                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                      GAMES
                    </h3>
                  </div>
                  <div className="mt-10 py-10 border-t border-gray-300 text-center">
                    <div className="flex flex-wrap justify-center"></div>
                  </div>
                  <div className="flex flex-wrap">
                    {this.state.results.map((info) => {
                      console.log(info);
                      if (info.status != "NA") {
                        return (
                          <CardGame
                            name={info.status}
                            key={info.id}
                            title={info.title}
                            //desc={info.status}
                            image={info.image}
                            //lnk={info.lnk}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className="container flex flex-col justify-center">
                <Link to={"/auth"}>
                  <Button className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-3xl">
                    Go To Form
                  </Button>
                </Link>

                <Link
                  to={"/perfettiAuth"}
                  
                >
                  <Button className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-3xl" >
                    Go To Perfetti Form
                  </Button>
                </Link>
                
              </div>
            </div>

            <FooterSmall absolute />
          </section>
        </main>
      </>
    );
  }
}

export default GamesDisplay;
