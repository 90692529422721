import React, { Component } from "react";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebase from "./../../utils/firebaseConfig";

//this is the OTP sms auth
class FirebaseAuth extends Component {
  uiConfig = {
    signInFlow: "popup",
    // signInSuccessUrl: "/gamespilot",
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "IN",
      },
    ],
     callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    }, 
  };

  render() {
    return (
      <div className="flex-col container mx-auto px-4 h-full">
{/*         <div className="bg-white shadow-lg rounded-lg m-8 mt-16">
        <h4 className="flex-auro mx-auto content-center items-center justify-center text-gray-800 font-semibold my-4 p-4"></h4>
        </div> */}
        <div className="flex content-center items-center justify-center h-full pt-16">
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      </div>
    );
  }
}

export default FirebaseAuth;
