import React from "react";
import CardGame from "components/Cards/CardGame";
import GameInfo from "components/Arrays/GameInfo";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import { userContext } from "utils/userContext";
import AuthWall from "./auth/AuthWallPilot";
import { hasRegistered } from "./../utils/authHelper";
import { isEligibleForPepsico } from "utils/profilingLogic";
import { isEligibleForPerfetti } from "utils/profilingLogic";
import { isEligibleForBata } from "utils/profilingLogic";

var GameDisplay = [];
export function listOfGames(user) {
  var arrOfGames = [];
  var id = 1;
  let game;
  if (isEligibleForBata(user)) {
    game = GameInfo.find((game) => game.name === "bata");
    if (game) {
      game["id"] = id;
      id++;
      arrOfGames.push(game);
    }
  }
  if (isEligibleForPerfetti(user)) {
    game = GameInfo.find((game) => game.name === "perfetti");
    if (game) {
      game["id"] = id;
      id++;
      arrOfGames.push(game);
    }
  }
  if (isEligibleForPepsico(user)) {
    game = GameInfo.find((game) => game.name === "pepsico");
    if (game) {
      game["id"] = id;
      id++;
      arrOfGames.push(game);
    }
  }
  if (arrOfGames.length === 0) {
    if (user.gender === "male") {
      game = GameInfo.find((game) => game.name === "price_it_right");
      game["id"] = id;
      id++;
      arrOfGames.push(game);
    } else {
      game = GameInfo.find((game) => game.name === "aytsoj");
      game["id"] = id;
      id++;
      arrOfGames.push(game);
    }
  }
  return arrOfGames;
}
export class Games extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <userContext.Consumer>
        {(user) => {
          GameDisplay = listOfGames(user);
          return !hasRegistered(user) ? (
            <AuthWall type="registration" />
          ) : (
            <>
              <Navbar />
              <section
                className="relative block h-500-px"
                style={{ height: "350px" }}
              >
                <div
                  className="absolute top-0 w-full h-full bg-center bg-cover"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/backgrounds/bkg.jpg") + ")",
                  }}
                >
                  <span
                    id="blackOverlay"
                    className="w-full h-full absolute opacity-50 bg-black"
                  ></span>
                </div>
                <div
                  className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                  style={{ transform: "translateZ(0)" }}
                >
                  <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                  >
                    <polygon
                      className="text-gray-300 fill-current"
                      points="2560 0 2560 100 0 100"
                    ></polygon>
                  </svg>
                </div>
              </section>
              <section className="relative py-16 bg-gray-300">
                <div className="container mx-auto px-4">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div className="px-6">
                      <div className="text-center mt-12">
                        <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                          GAMES
                        </h3>
                      </div>
                      <div className="mt-10 py-10 border-t border-gray-300 text-center">
                        <div className="flex flex-wrap justify-center"></div>
                      </div>
                      <div className="flex flex-wrap">
                        {GameDisplay.map((info) => {
                          return (
                            <CardGame
                              name={info.name}
                              key={info.title}
                              title={info.title}
                              desc={info.desc}
                              image={info.image}
                              lnk={info.lnk}
                              hasLeaderboard={info.hasLeaderboard}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </>
          );
        }}
      </userContext.Consumer>
    );
  }
}
