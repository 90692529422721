import React, { Component } from 'react'

// import Paper from '@mui/material/Paper';
import Typography from "@material-ui/core/Typography";

import firebase from "./../utils/firebaseConfig";
import "firebase/database";
import "./TicketLeaderboard.css";

import AuthNavbarOT from "components/Navbars/AuthNavbarOT";

const database = firebase.database();

var arrOfUsers=[];
class TicketLeaderboard extends Component {
  constructor(props){
    super(props)
    this.state = {
        results:[],
    }

    
  }

  componentDidMount = async () => {

    const ref = database.ref("OTusers");
    
    var snapshot = await ref
      .orderByChild("numberOfTickets")
      .limitToLast(10)
      .once("value");
    snapshot.forEach((child) => {

        this.setState(prevState => ({
            results: [...prevState.results, child.val()]
          }));

          
    });


    
  }


  render(){

        return(
      
       <div>
            <div class="flex flex-col justify-center items-center">
            <div class="flex-auto mt-2 justify-center">
            <Typography variant="h6" gutterBottom>
                          MAXI Fair Team LEADERBOARD
                  </Typography>
             </div>
             <div class="flex-auto m-2 p-2 justify-center">
                  <Typography variant="subtitle1" gutterBottom>
                          Ticket Sales
                  </Typography>
              
                  <table class="table-auto subpixel-antialiased border-spacing-2">
                    


                <tbody>
                {this.state.results.sort(function(a,b){
    return b.numberOfTickets - a.numberOfTickets;
}).map((user,i)=>{ if(user.numberOfTickets>0)
                        return(
                  <tr>
                    <td>
                     <Typography variant="subtitle1" gutterBottom>
                        

                          {i+1}
                 
                  {/* {i==0 ? (
                      <img height="5%" width="5%" src={require("assets/img/icons/gold.png")} style={{ display:"inline-block", float: "center"}}></img>
                    ) : i==1 ? (
                        <img height="5%" width="5%" src={require("assets/img/icons/silver.png")} style={{ display:"inline-block", float: "center"}}></img>
                      ): i==2 ? (
                        <img height="5%" width="5%" src={require("assets/img/icons/bronze.png")} style={{ display:"inline-block", float: "center"}}></img>
                      ) : ""} */}
                      
                       </Typography>

                  </td>
                  
                  

                    <td >
                    <Typography variant="subtitle1" component="h2">
                          {user.name}
                    </Typography>
                    </td>
                 
                    <td>
                  <Typography variant="subtitle1" component="h2">
                          {user.numberOfTickets}
                  </Typography>
                  </td>
                    
                  </tr>)
                  })}
                </tbody>
              </table>

          

            

        
    </div>
    </div>
    </div>
    
    )
  }
}

export default TicketLeaderboard;