import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { PropTypes } from "prop-types";
import FooterTerms from "components/Footers/FooterPage.js";
import ReactGA from 'react-ga';
import BannerImage from "assets/img/backgrounds/header_img_pilot2.webp";
import { withStyles } from "@material-ui/core/styles";
const TransparentTextTypography = withStyles({
  root: {
    color: "rgba(0,0,0,0)"
  }
})(Typography);

const WhiteTextTypography = withStyles({
  root: {
    color: "white"
  }
})(Typography);

class AuthWall extends Component {
  static get propTypes() {
    return {
      type: PropTypes.any, //can be 'payment' or 'registration'
    };
  }
  eventProceed() {
    ReactGA.event({
      category: 'User',
      action: 'Landing Page - Click to proceed'
    });
  }

  render() {
    return (
      <>
        <main>
          <section
            className="relative w-full h-full py-5 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/backgrounds/register_bg_2.png") +
                ")",
            }}
          >
          <div className = "flex-col items-center place-content-stretch">
            <div className="relative m-2 flex-auto">
              <img src={BannerImage} alt="BannerImage" className="mx-auto h-3/4 w-3/4 md:h-1/2 md:w-1/2 object-center relative"/> 
            </div>
          </div>
          
            <Card className="container mx-auto content-center items-center justify-center text-center m-2">
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {this.props.type === "payment"
                      ? "Buy a Ticket"
                      : "Let’s Get Started!"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {
                      "You’re only one step away from playing the game and winning prizes!"
                    }
                  </Typography>
                </CardContent>
                <Link
                  to={"/authpilot"}
                  className={
                    "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
                  }
                >
                  <Button onClick={() => this.eventProceed()}
                            variant="contained"
                            color="secondary"
                            style={{
                              padding: "1rem 2rem",
                              fontSize: "18px",
                              fontWeight: "bold",
                              margin: "20px",
                            }}
                          >
                            Click to Proceed
                          </Button>
                </Link>
              </CardActionArea>
            </Card>

            <FooterTerms /> 
          </section>
        </main>
      </>
    );
  }
}

export default AuthWall;




