/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { userContext } from "utils/userContext";
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import ReactGA from 'react-ga';

var hasPlayed = false;
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: "auto",
    marginBottom: "4rem",
  },
});

export default function CardGame(props) {
  const classes = useStyles();

  function eventProceed(location){
    ReactGA.event({
      category: 'User',
      action: location,
    });
  }

  return (
    <>
      <Card className={classes.root}>
      <CardActions>
          <userContext.Consumer>
            {(user) => {
              hasPlayed = user.games
                ? user.games[props.name] !== undefined
                : false;
              return hasPlayed ? (
                props.hasLeaderboard ? (
                  <Link
                    to={props.lnk}
                    className={
                      "text-sm text-center font-normal w-full whitespace-no-wrap text-gray-800"
                    }
                  >
                    <Button size="large" variant="contained" color="primary" startIcon={<SportsEsportsIcon />}>
                      View Score
                    </Button>
                  </Link>
                ) : (
                  <div className="text-sm text-center font-normal w-full whitespace-no-wrap text-gray-800">
                    <Button size="small" color="primary" disabled>
                      Played
                    </Button>
                  </div>
                )
              ) : (
              <div className="container flex flex-col justify-center">
                {/* <Link
                  to={props.lnk}
                  className={
                    "text-center  w-full whitespace-no-wrap"
                  }
                >
                  <Button className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-3xl" onClick={eventProceed('Clicked Play Now')} size="large" variant="contained" startIcon={<SportsEsportsIcon />}>
                    Play Now
                  </Button>
                </Link>
                <div className="text-xs text-center textfont-semibold  mb-2 text-gray-800">
                <h3 >By clicking on Play Now, I agree to the </h3>
                <a href="https://docs.google.com/document/d/1z9K_I08hVDvALS5Ckzd4EMOup_XrMboQV2seZR9TWpk/edit">
                                   Terms & Conditions
                              </a>
                </div> */}
              </div>
              );
            }}
          </userContext.Consumer>
        </CardActions>
        
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="50"
            image={props.image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" align="center">
              {props.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              align="center"
            >
              {props.desc}
            </Typography>
          </CardContent>
        </CardActionArea>
       
      </Card>
    </>
  );
}
