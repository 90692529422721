import React, { Suspense, lazy } from "react";
import { userContext } from "utils/userContext";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { LiveChatLoaderProvider, Chatwoot } from "react-live-chat-loader";
import { ToastContainer, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

// views without layouts
import firebase from "./utils/firebaseConfig";
import "firebase/database";
import { dbGetGame, dbGetUserReference, dbGetOTUserReference } from "utils/firebaseDB";
import { Games } from "views/Games";
import { GamesPilot } from "views/GamesPilot";
import { Spinner } from "./components/Spin.js";
import GameInfo from "./components/Arrays/GameInfo";
import GameLanding from "views/MaxiFair/GameLanding";
import TicketAllotment from "components/TicketAllotment";
import TicketTracker from "components/TicketTracker";
import MaxiFairShortDesc from "views/MaxiFair/MaxiFairShortDesc";
import GamesDisplay from "layouts/GamesDisplay";
import CheckGameStatus from "components/checkGameStatus";
import PerfettiStatusUpdate from "views/MaxiFair/PerfettiStatusUpdate";
import PepsicoStatusUpdate from "views/MaxiFair/PepsicoStatusUpdate";
import BataStatusUpdate from "views/MaxiFair/BataStatusUpdate";
import ZeeStatusUpdate from "views/MaxiFair/ZeeStatusUpdate";
import IciciStatusUpdate from "views/MaxiFair/IciciStatusUpdate";
import GamesStatus from "layouts/GamesStatus";
import PerfettiAuth from "layouts/PerfettiAuth";


const Privacy = lazy(() => import("views/PrivacyPolicy"));
const Terms = lazy(() => import("views/TermsConditions"));

// layouts
const Admin = lazy(() => import("layouts/Admin.js"));

const Auth = lazy(() => import("layouts/Auth.js"));
const Authpilot = lazy(() => import("layouts/AuthPilot.js"));
const OTAuth = lazy(() => import("layouts/OTAuth.js"));


// views without layouts
const FlipToProShow = lazy(() => import("./views/MaxiFair/FlipToProShow"));
const Album = lazy(() => import("views/Album"));
const Game = lazy(() => import("./components/Game"));
const TicketLeaderboard = lazy(() => import("./components/TicketLeaderboard"));
const QRScanner = lazy(() => import("./components/QRScanner"));
const GamePilot = lazy(() => import("./components/GamePilot"));
// const Compendium = lazy(() => import("./views/Compendium.js"));
const Landing = lazy(() => import("views/Landing.js"));
const Alumni = lazy(() => import("./views/Alumni.js"));
const MaxiFair = lazy(() => import("./views/MaxiFair/MaxiFair.js"));
const RtmInvite = lazy(() => import("./views/MaxiFair/RtmInvite.js"));
const Team = lazy(() => import("./views/Team.js"));
const Profile = lazy(() => import("views/Profile.js"));
const Timeline = lazy(() => import("./views/Timeline.js"));
const News = lazy(() => import("./views/News.js"));
const DummyTicket = lazy(() => import("./views/auth/DummyTicket"));
const Sponsors = lazy(() => import("./views/MaxiFair/Sponsors"));
const ProShow = lazy(() => import("./views/ProShow"));
const Sponsors2024 = lazy(() => import("./views/Sponsors"));
const ArtAttack = lazy(() => import("./views/ArtAttack"));
const MissJamshedpur = lazy(() => import("./views/MissJamshedpur"));
const MasterChef = lazy(() => import("./views/MasterChef"));
const FavoriteFamily = lazy(() => import("./views/FavoriteFamily"));
const SportsMania = lazy(() => import("./views/SportsMania"));
const FancyDress = lazy(() => import("./views/FancyDress"));
const DanceMania = lazy(() => import("./views/DanceMania"));
const JsrQuiz = lazy(() => import("./views/JsrQuiz"));
const Schedule2024 = lazy(() => import("./views/Schedule2024"));
const EventRegistration = lazy(() =>
  import("views/MaxiFair/EventRegistration")
);
const Faqs = lazy(() => import("views/Faqs"));
const GameEligibity = lazy(() => import("./views/MaxiFair/GameEligibity"));

const subtitle_2020 = "Maxi Fair 2020, Jamshedpur";
const subtitle_2019 = "Maxi Fair 2019, Jamshedpur";
const description_sectionwars =
  "MAXI being XLRI's oldest and most renowned committee, extended the decade-old legacy of Section Wars by organizing the Injustice League. The event successfully engaged all six sections of the batch of 2021 (XLRI) in the 24 hour long extravaganza which had a number of fun events and competitions ranging from Meme Wars to Brand Wars. In other words, there was something for everyone. The competitive spirit among different sections had never been higher and so had been the dedication of MAXI to deliver it's first event of the year!";
const description_fair =
  "Marketing Fair, or MAXI Fair as it is more popularly known, is an innovative tool for conducting market research and consumer behavior pioneered by XLRI.";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidUpdate() {
    // send userInfo to Android app
    try {
      // eslint-disable-next-line no-undef
      Android.userInfo(JSON.stringify(this.state.user));
    } catch (err) {
      console.log("not android app");
    }
  }

  async componentDidMount() {
    this.updateUserContext = this.updateUserContext.bind(this);
    this.clearUserContext = this.clearUserContext.bind(this);
    this.updateGameContext = this.updateGameContext.bind(this);

    // send userInfo to Android app
    try {
      // eslint-disable-next-line no-undef
      Android.userInfo(JSON.stringify(this.state.user));
    } catch (err) {
      console.log("not android app");
    }

    var tempUser = { isSignedIn: false, isFormFilled: false };
    // get and set currently logged in user to state
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        tempUser.isSignedIn = true;
        tempUser.mobile = user.phoneNumber;
        tempUser.games = {};
        this.setState({ user: tempUser });
        const that = this;

        dbGetUserReference(user.phoneNumber).on(
          "value",
          function (snapshot) {
            var data = snapshot.val();
            if (data) {
              tempUser.isFormFilled = true;
              tempUser = { ...tempUser, ...data };
              that.setState({ user: tempUser });
            }
          },
          function (errorObject) {
            console.log("Failed to read user db: " + errorObject.code);
          }
        );

        dbGetOTUserReference(user.phoneNumber).on(
          "value",
          function (snapshot) {
            var data = snapshot.val();
            if (data) {
              tempUser.isFormFilled = true;
              tempUser = { ...tempUser, ...data };
              that.setState({ user: tempUser });
            }
          },
          function (errorObject) {
            console.log("Failed to read user db: " + errorObject.code);
          }
        );

        GameInfo.map((info) => {
          dbGetGame(info.name, user.phoneNumber).then((data) => {
            if (data) {
              tempUser.games[info.name] = data;
              this.setState({ user: tempUser });
            }
          });
          return true;
        });
      }
    });
  }

  updateUserContext(update) {
    this.setState((prevState) => {
      var newUser = { ...prevState.user, ...update };
      prevState.user = newUser;
      return prevState;
    });
  }

  updateGameContext(game) {
    this.setState((prevState) => {
      var prevGames = prevState.user.games;
      var newGames = { ...prevGames, ...game };
      prevState.user.games = newGames;
      return prevState;
    });
  }

  overwriteUserContext(user) {
    this.setState({ user: user });
  }

  clearUserContext() {
    this.setState({ user: {} });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    return (
      // Pass user state as value to context.Provider so it can be consumed by context.Consumer
      <userContext.Provider value={this.state.user}>
        {/* <LiveChatLoaderProvider
          provider="chatwoot"
          providerKey="LTCt7QsDqMqw14usHEAihw7s"
        >
          <Chatwoot />
        </LiveChatLoaderProvider> */}
        <BrowserRouter>
          <Suspense fallback={<Spinner />}>
            <Switch>
              {/* add routes with layouts */}
              <Route path="/admin" component={Admin} />
              <Route
                path="/auth"
                component={() => (
                  <Auth updateUserContext={this.updateUserContext} />
                )}
              />
              <Route
                path="/authpilot"
                component={() => (
                  <Authpilot updateUserContext={this.updateUserContext} />
                )}
              />
              <Route
                path="/updateStatusGame1"
                exact component={PerfettiStatusUpdate} />

              <Route
                path="/updateStatusGame3"
                exact component={PepsicoStatusUpdate} />

              <Route
                path="/perfettiAuth"
                component={() => (
                  <PerfettiAuth updateUserContext={this.updateUserContext} />
                )}
              />

              <Route
                path="/updateStatusGame4"
                exact component={BataStatusUpdate} />

              <Route
                path="/updateStatusGame5"
                exact component={ZeeStatusUpdate} />

              <Route
                path="/updateStatusGame2"
                exact component={IciciStatusUpdate} />

              {/* <Route
                path="/compendium"
                exact component={Compendium} /> */}
              
              <Route
                path="/fair/OTLeaderboard"
                component={() => <TicketLeaderboard />}
              />
              <Route
                path="/OTauth"
                component={() => (
                  <OTAuth updateUserContext={this.updateUserContext} />
                )}
              />
              <Route
                path="/checkGamesStatus"
                component={() => (
                  <CheckGameStatus />
                )}
              />
              {/* add routes without layouts */}
              <Route path="/landing" exact component={Landing} />
              <Route
                path="/fairDescription"
                exact
                component={MaxiFairShortDesc}
              />

              <Route path="/alumni" exact component={Alumni} />
              <Route path="/team" exact component={Team} />
              <Route path="/fair" exact component={MaxiFair} />
              <Route path="/gamesDisplay/*"  component={GamesDisplay} />
              <Route path="/gamesStatus/*"  component={GamesStatus} />
              <Route path="/fair/" exact component={MaxiFair} />
              <Route path="/rtminvite" exact component={RtmInvite} />
              <Route path="/dj" exact component={FlipToProShow} />
              <Route
                path="/profile"
                component={() => (
                  <Profile clearUserContext={this.clearUserContext} />
                )}
              />

              <Route
                path="/fair/ticket/allotment"
                exact
                component={TicketAllotment}
              />
              <Route
                path="/fair/ticket/tracking"
                exact
                component={TicketTracker}
              />

              <Route path="/timeline" exact component={Timeline} />
              <Route path="/news" exact component={News} />
              <Route path="/games" exact component={Games} />
              <Route path="/gamespilot" exact component={GamesPilot} />
              <Route path="/check" exact component={GameEligibity} />
              <Route path="/fairTicket" exact component={DummyTicket} />
              <Route path="/faqs" exact component={Faqs} />
              <Route path="/sponsors" exact component={Sponsors} />
              <Route path="/fair2021/sponsors" exact component={Sponsors} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="/terms" exact component={Terms} />
              <Route
                path="/GameLanding"
                component={() => (
                  <GameLanding updateUserContext={this.updateUserContext} />
                )}
              />
              <Route
                path="/eventRegistration"
                exact
                component={EventRegistration}
              />

              <Route
                path="/games/price_it_right"
                exact
                component={() => (
                  <Game
                    name={"price_it_right"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
              <Route
                path="/games/aytsoj"
                exact
                component={() => (
                  <Game
                    name={"aytsoj"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
              <Route
                path="/games/hungry_runner"
                exact
                component={() => (
                  <Game
                    name={"hungry_runner"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
              <Route
                path="/games/test"
                exact
                component={() => (
                  <Game
                    name={"test"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
              <Route
                path="/games/lifeSahiHai"
                exact
                component={() => (
                  <Game
                    name={"lifeSahiHai"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
              <Route
                path="/games/lockdownDiaries"
                exact
                component={() => (
                  <Game
                    name={"hul"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />

              <Route
                path="/gamespilot/lockdownDiaries"
                exact
                component={() => (
                  <GamePilot
                    name={"hul"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />

              <Route
                path="/games/GkdMeEkDin"
                exact
                component={() => (
                  <Game
                    name={"itc"}
                    updateGameContext={this.updateGameContext}
                  />
                )}
              />
               <Route
                path="/fair/sponsors"
                exact
                component={Sponsors2024}
              />

              <Route
                path="/fair/schedule"
                exact
                component={Schedule2024}
              />

              <Route
                path="/events/fair2024/art_attack"
                exact
                component={ArtAttack}
              />
              <Route
                path="/events/fair2024/favorite_family"
                exact
                component={FavoriteFamily}
              />
              <Route
                path="/events/fair2024/dance_mania"
                exact
                component={DanceMania}
              />
              <Route
                path="/events/fair2024/sports_mania"
                exact
                component={SportsMania}
              />

              <Route
                path="/events/fair2024/fancy_dress"
                exact
                component={FancyDress}
              />

              <Route
                path="/events/fair2024/master_chef"
                exact
                component={MasterChef}
              />
              <Route
                path="/events/fair2024/miss_jamshedpur"
                exact
                component={MissJamshedpur}
              />
              <Route
                path="/events/fair2024/jamshedpur_quiz"
                exact
                component={JsrQuiz}
              />
              <Route
                path="/events/fair2021/pro_show"
                exact
                component={ProShow}
              />
              <Route
                path="/events/fair2020/art_attack"
                component={() => (
                  <Album
                    title={"Art Attack"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/ArtAttack"}
                    numberOfImages={6}
                  />
                )}
              />
              <Route
                path="/events/fair2020/favorite_family"
                component={() => (
                  <Album
                    title={"Favorite Family"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/FavoriteFamily"}
                    numberOfImages={6}
                  />
                )}
              />
              <Route
                path="/events/fair2020/kids_fashion_show"
                component={() => (
                  <Album
                    title={"Kids Fashion Show"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/KidsFashionShow"}
                    numberOfImages={9}
                  />
                )}
              />
              <Route
                path="/events/fair2020/master_chef"
                component={() => (
                  <Album
                    title={"Master Chef"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/MasterChef"}
                    numberOfImages={9}
                  />
                )}
              />
              <Route
                path="/events/fair2020/miss_jamshedpur"
                component={() => (
                  <Album
                    title={"Miss Jamshedpur"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/MissJamshedpur"}
                    numberOfImages={6}
                  />
                )}
              />
              <Route
                path="/events/fair2020/pro_show"
                component={() => (
                  <Album
                    title={"Pro Show"}
                    subtitle={subtitle_2020}
                    description={description_fair}
                    folderPath={"MAXI2020/ProShow"}
                    numberOfImages={6}
                  />
                )}
              />
              <Route
                path="/events/fair2019/main"
                component={() => (
                  <Album
                    title={"MAXI Fair 2019"}
                    subtitle={subtitle_2019}
                    description={description_fair}
                    folderPath={"MAXI2019/Fair"}
                    numberOfImages={7}
                  />
                )}
              />

              <Route
                path="/events/sectionwars2020/main"
                component={() => (
                  <Album
                    title={"Section Wars 2020"}
                    subtitle={"XLRI, 2020"}
                    description={description_sectionwars}
                    folderPath={"MAXI2020/SectionWars"}
                    numberOfImages={2}
                  />
                )}
              />

              <Route
                path="/events/sectionwars2019/main"
                component={() => (
                  <Album
                    title={"Section Wars 2019"}
                    subtitle={"XLRI Jamshedpur, 2019"}
                    description={description_sectionwars}
                    folderPath={"MAXI2019/SectionWars"}
                    numberOfImages={6}
                  />
                )}
              />

              <Route path="/" exact component={Landing} />

              {/* add redirect for first page */}
              <Redirect from="*" to="/fair" />
            </Switch>
          </Suspense>
        </BrowserRouter>
        <ToastContainer transition={Slide} />
        
      </userContext.Provider>
    );
  }
}

export default App;
