/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../assets/img/icons/maxi2024.png";
import { BrowserView, MobileView } from "react-device-detect";
// components
import PagesDropdown from "components/Dropdowns/PagesDropdown.js";
import { userContext } from "utils/userContext";
import MobileViewNavItems from "components/Dropdowns/MobileViewNavBar";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
    
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link className ="" to="/">
              <img src={logoImage} alt="MAXI" style={{maxHeight:'35px'}}></img>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <Link
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                to="/fair"
              >
                MAXI FAIR
              </Link>
              {/* <Link
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                to="/timeline"
              >
                Past Events
              </Link> */}
              <Link
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                to="/news"
              >
                Media & PR
              </Link>

              <Link
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                to="/team"
              >
                Meet the Team
              </Link>
              <Link
                className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                to="/alumni"
              >
                MAXI Mafia
              </Link>

              {/* <li className="flex items-center">
                <BrowserView>
                  <PagesDropdown />
                </BrowserView> */}
                <MobileView>
                    <MobileViewNavItems />
                    
                </MobileView>

              {/* </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
