import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
// eslint-disable-next-line no-unused-vars
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

export default class App extends Component {
  render() {
    const imageStyle = {
      filter: "brightness(70%)",
      // height:"170px"
    };
    if(window.screen.width<600){
      imageStyle.height = "170px"    
    }
    return (
      <div>
        <Carousel
          autoPlay
          interval={6000}
          showStatus={false}
          useKeyboardArrows={false}
          stopOnHover={false}
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          // onClickItem={() => {
          //   window.location.href = "/fair";
          // }}
        >
          <div>
            <img
              alt="carousel1"
              style={imageStyle}
              src={require("assets/img/carousel/carFair-1.jpeg")}
            ></img>
          </div>

          <div>
            <img
              alt="carousel3"
              style={imageStyle}
              src={require("assets/img/carousel/carousel1.webp")}
            ></img>
          </div>
          <div>
            <img
              alt="carousel4"
              style={imageStyle}
              src={require("assets/img/carousel/carFair-2.jpg")}
            ></img>
          </div>
        </Carousel>
      </div>
    );
  }
}
