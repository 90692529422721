import React, { Component } from "react";
import * as Survey from "survey-react";
import { OTformJson } from "./OTFormJson";
import "survey-react/survey.css";
import { PropTypes } from "prop-types";

export default class OTForm extends Component {
  static get propTypes() {
    return {
      onFormFill: PropTypes.any,
    };
  }

  survey = new Survey.Model(OTformJson);

  constructor(props) {
    super(props);
    this.onComplete = this.onComplete.bind(this);
    //Survey.StylesManager.applyTheme("modern");
    Survey.StylesManager.ThemeColors['default']['$main-color'] = '#c084fc';
    Survey.StylesManager.applyTheme("default");
  }


  onComplete(survey) {
    this.props.onFormFill(survey.data);
  }

  render() {
    return (
      <>
        <main className="profile-page">
          <section
            className="relative block h-500-px"
            style={{ height: "350px" }}
          >
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900"
            >
            </div>
            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-gray-300 fill-current"
                  points="2560 0 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </section>

          <section className="relative py-16 bg-gray-300">
            <div className="container mx-auto px-4 min-w-min w-3/5">
              <div className="relative flex flex-col min-w-0 break-words bg-white  mb-6 shadow-xl rounded-lg -mt-96">
              <Survey.Survey
            model={this.survey}
            showCompletedPage={false}
            onComplete={this.onComplete}
          />
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
