import React, { Component } from "react";
import { PropTypes } from "prop-types";
import "./ticket.css";
import logoImage from "../../assets/img/icons/Logo.png";
import sponsImage1 from "../../assets/img/companies/indico.jpg";
import sponsImage2 from "../../assets/img/companies/jaivik.jpg";
import sponsImage3 from "../../assets/img/companies/uco.jpg";
import sponsImage4 from "../../assets/img/companies/dainik.jpg";
import sponsImage5 from "../../assets/img/companies/pnb.png";
import sponsImage6 from "../../assets/img/companies/radio.jpg";
var QRCode = require("qrcode.react");

export default class Ticket extends Component {
  static get propTypes() {
    return {
      name: PropTypes.any,
      maxiId: PropTypes.any,
    };
  }

  render() {
    return (
      <div className="tcardWrap">
        <div className="tcard tcardLeft">
          <div className="theading">
            <h2>
              42<sup>nd</sup> MAXI <span>FAIR</span>
            </h2>
          </div>
          <div className="ttitle">
            <h2>{this.props.name}</h2>
            <span>name</span>
          </div>
          <div className="tname">
            <p className="link1">
              <a href="https://maxi-xlri.in/fair">maxi-xlri.in/fair</a>
            </p>

            <span>schedule</span>
          </div>
          <div className="tseat">
            <h2>₹ 50</h2>
            <span>price</span>
          </div>
          <div className="ttime">
            <h2>30-31 Jan &apos;21</h2>
            <span>date</span>
          </div>

          <div className="thelp">
            <h2>Madhav - 9465010239</h2>
            <h2>Gino - 9819592431</h2>
            <span>Contact for help</span>
          </div>
        </div>
        <div className="tcard tcardRight">
          <img src={logoImage} alt="MAXI"></img>

          <QRCode className="tqr" size={64} value="https://maxi-xlri.in/fair" />

          <div className="tmaxiid">
            <h2>{this.props.maxiId}</h2>
          </div>

          <div className="tspons">
            <img className="tsponsimg" src={sponsImage1} alt="MAXI"></img>
            <img className="tsponsimg" src={sponsImage2} alt="MAXI"></img>
            <img className="tsponsimg" src={sponsImage3} alt="MAXI"></img>
          </div>

          <div className="tspons">
            <img className="tsponsimg" src={sponsImage4} alt="MAXI"></img>
            <img className="tsponsimg" src={sponsImage5} alt="MAXI"></img>
            <img className="tsponsimg" src={sponsImage6} alt="MAXI"></img>
          </div>

          <div className="tsponstext">
            <span>Sponsored By</span>
          </div>
        </div>
      </div>
    );
  }
}
