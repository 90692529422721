/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";

import { userContext } from "utils/userContext";
import { isPaymentCompleted } from "utils/authHelper";

const MobileViewNavItems = () => {
 
  return (
    <>
       
        <userContext.Consumer>
          {(user) => {
            return user.isFormFilled ? (
              <span
                className={
                  "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"
                }
              >
                {user.name}&apos;s Corner
              </span>
            ) : (
              <span
                className={
                  "text-sm pt-2 pb-0 px-3 py-4  font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"
                }
              >
                <p> Registration Corner</p>
              </span>
            );
          }}
       </userContext.Consumer>

        {
          <Link
            to="/auth"
            className={
              "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
            }
          >
            <userContext.Consumer>
              {(user) => {
                return !user.isSignedIn ? (
                  <p>Log In</p>
                ) : !user.isFormFilled ? (
                  <p>Register Now</p>
                ) : !isPaymentCompleted(user) ? (
                  <p>Purchase Ticket</p>
                ) : (
                  <p>View Ticket</p>
                );
              }}
            </userContext.Consumer>
          </Link>
        }

        <Link
          to="/profile"
          className={
            "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          }
        >
          Profile
        </Link>

        <div className="h-0 mx-4 my-2 border border-solid border-gray-200" />
        <span
          className={
            "text-sm pt-2 pb-0 px-3 py-4 font-bold block w-full whitespace-no-wrap bg-transparent text-gray-500"
          }
        >
          MAXI Family
        </span>

        <Link
          className={
            "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          }
          to="/team"
        >
          The Team
        </Link>

        <Link
          className={
            "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
          }
          to="/alumni"
        >
          Alumni
        </Link>
      
    </>
  );
};

export default MobileViewNavItems;
