export function isPaymentCompleted(user) {
  return (
    user.isSignedIn &&
    user.isFormFilled &&
    user.paymentData &&
    user.paymentData.resultStatus &&
    user.paymentData.resultStatus === "TXN_SUCCESS"
  );
}

export function didPreviousPaymentFail(user) {
  return (
    user.isSignedIn &&
    user.isFormFilled &&
    user.paymentData &&
    user.paymentData.resultStatus &&
    user.paymentData.resultStatus !== "TXN_SUCCESS"
  );
}

export function hasRegistered(user) {
  return user.isSignedIn && user.isFormFilled;
}
