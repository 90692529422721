import React from "react";
import AuthNavbarPilot from "components/Navbars/AuthNavbarPilot";
import Form from "views/auth/Form";
import { userContext } from "utils/userContext";
import RShowerImage from "assets/img/backgrounds/rupee_shower.webp";
import LGamerImage from "assets/img/backgrounds/gamer.webp";
import { GamesPilot } from 'views/GamesPilot';
import AuthModal from "components/Modals/AuthModal";
import { PropTypes } from "prop-types";
import moment from "moment";
import { dbCreateUser } from "utils/firebaseDB";
import { exportComponentAsJPEG } from "utils/exportAsJpg";
import { backendURL } from './../../utils/backendInfo';
import FooterPilot from 'components/Footers/FooterPilot.js';
import AboutUs from './../AboutUs.js';
import ReactGA from 'react-ga';

const axios = require("axios");
const { customAlphabet } = require("nanoid");

// eslint-disable-next-line react/prop-types
class GameLanding extends React.Component {

  static get propTypes() {
    return {
      updateUserContext: PropTypes.any,
    };
  }

  eventProceed(location) {
    ReactGA.event({
      category: 'User',
      action: location,
    });
  }

  mobile = null;
  ticketRef = React.createRef();
  shareUrl = "https://maxi-xlri.in/fairTicket";
  shareTitle = "Join me online at the 43rd MAXI Fair on Jan 21-22 2023!";

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    };
    this.loginpopup = this.loginpopup.bind(this);
    this.eventProceed = this.eventProceed.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.eventProceed('Landing Page Visit');
  }


  
  loginpopup(){
    this.setState({ showPopup: (!this.state.showPopup) });
    this.eventProceed('Register button');
  
  }

  onFormFill = (data) => {
    if (this.mobile) {
      data.mobile = this.mobile;
      //data.numberOfTickets=0;
      const alphabet =
        "123456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz";
      const nanoid = customAlphabet(alphabet, 10);
      data.maxiId = "MAXI-" + nanoid(); //=> "dCok8DprjX"
      data.createdAt = moment().format("DD/MM/YYYY , HH:mm:ss");

      this.props.updateUserContext({
        ...data,
        isSignedIn: true,
        isFormFilled: true,
      });
      dbCreateUser(data);
      //send data to backend for newly filled form
      axios.post(backendURL + "/users", data).then(function () {});
      this.eventProceed('Form filled');
    }
  };

  onSaveTicket = () => {
    window.scrollTo(0, 0);
    exportComponentAsJPEG(this.ticketRef, { fileName: "MaxiFairTicket.jpg" });
  };

  render() {
    return (
      <>
        <main>
          <section
            className="relative w-full h-full min-h-screen  bg-white bg-no-repeat bg-full"
          >
            <userContext.Consumer>
              {(user) => {
                this.mobile = user.mobile;
                return (
                  <div>
                    {
                      user.isSignedIn ? (
                        user.isFormFilled ? (
                          <GamesPilot />
                        ) :(
                          <Form onFormFill={this.onFormFill} />
                        )
                      )
                        : (

          <>
            <div>
              <AuthNavbarPilot btnState={this.loginpopup}/>
              {/* <ParticlesBg type="circle" bg={true} color="#58c3fc" /> */}

              <div className="fixed hidden inset-0 overflow-y-auto h-full w-full">
                { this.state.showPopup ? <AuthModal btnState={this.loginpopup}/> : null }
               </div>
            <div className="flex flex-col h-screen justify-center items-center">

            <section className="relative text-center justify-center mx-auto mt-6 sm:mt-4 items-center w-3/4 rounded-3xl max-w-screen-xl max-h-32">
                  <div>
                     <span className="inline-flex text-center text-white bg-purple-700 text-3xl sm:text-4xl font-bold font-sans p-2 rounded-lg">₹50</span>
                     <span className="text-center flex-auto text-purple-400 text-3xl sm:text-4xl font-bold font-sans py-2 italic"> Assured prize on completion of game!</span>        
                  </div>
           </section>
           
              <section className="relative justify-center mx-auto mt-10 mb-10 pt-10 items-center h-3/5 w-3/4 bg-cover bg-herobgcmp sm:bg-herobg md:bg-auto md:bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 rounded-3xl max-w-screen-xl max-h-96">
                 <div className="flex flex-nowrap flex-row flex-auto justify-center pt-1 gap-3 rounded-3xl drop-shadow-xl">
                     <div className="flex-auto basis-1/6 self-center overflow-visible invisible md:visible">
                     <img src={LGamerImage} alt="Rupees" className="object-center relative md:scale-[2] lg:scale-125 md:-ml-16 max-h-72"/>
                     </div>
                  <div>
                    <div className="text-center flex-auto text-amber-50 text-4xl font-semibold font-sans pt-5">
                     <h1>Play free games, win CASH prizes!</h1>
                     <div className="text-center flex-auto text-amber-50 text-xl font-medium font-sans pt-5">
                     <h4>Register. Play. Win! It&#39;s that simple</h4>
                     </div>
                     <button type="button" onClick={this.loginpopup}
                     className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-4xl xl:text-5xl rounded-lg px-5 py-2.5 text-center mr-2 mb-5 mt-5 xl:mt-10">PLAY NOW</button>
                    </div>
                    
                  </div>
                    <div className="flex-auto basis-1/6 self-center overflow-visible invisible md:visible">
                       <img src={RShowerImage} alt="Rupees" className="rotate-[60deg] md:scale-[2] lg:scale-125 object-center relative md:translate-y-20 lg:translate-y-0 max-h-96 xl:translate-x-10"/>
                    </div>
                  </div>
              </section>

</div>
              {/*<section id="eventsRedirect">
               < AboutUs />
               </section>
                        */}
              <section 
              id = "AboutUs"
              className="relative py-20 bg-white">
            
            <div className="relative flex flex-col min-w-0 break-words bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 mt-10 mb-10 mx-10 mb-6 shadow-xl rounded-3xl -mt-64">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal text-yellow-500 mb-2">
                  About Us
                  </h3>
                  <p className="text-lg text-amber-200">
                  The Marketing Association of XLRI, or MAXI as it is
                      fondly known, is the oldest committee on campus with a
                      fifty year legacy. Continuing the legacy, we are now extending this experience online, through MAXI Games - a fun and interactive way of engaging our audience!
                  </p>

                </div>
                
                

            <div className="container mx-auto px-4 lg:pt-12 lg:pb-32">
            <div className="flex flex-wrap mt-8 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-yellow-500">
                  World Records
                </h6>
                <p className="mt-2 mb-4 text-amber-200">
                  An ISO 9001-2000 certified organization, MAXI holds both a
                  Limca and a Guinness World Record.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-yellow-500">
                  A legacy of 50 years
                </h5>
                <p className="mt-2 mb-4 text-amber-200">
                  Established way back in 1971, MAXI enjoys unparalled reach
                  on social media platforms.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                  <i className="fas fa-handshake text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-yellow-500">
                  Industry Partnerships
                </h5>
                <p className="mt-2 mb-4 text-amber-200">
                  Helped launch and improve products for prestigious companies
                  such as Pepsi, Nestle and HUL.
                </p>
              </div>
            </div>
            <div className="text-center flex-auto text-amber-50 text-4xl font-semibold font-sans pt-5">
            
            <button type="button" 
                   className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-4xl xl:text-5xl rounded-lg px-5 py-2.5 text-center mr-2 mb-5 mt-5 xl:mt-10"
                   onClick={this.loginpopup}
                   >PLAY GAMES</button>
                 </div>
               </div>
             </div>
            
          </div> {/*About Us Div */}
        </section>
            

            
            </div>
            
          </>
                          
          )}

        </div>
        );
      }}
            </userContext.Consumer>
           <FooterPilot />
          </section> 
        </main>
      </>
    );
  }
}

export default GameLanding;
