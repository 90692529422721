const GameInfo = [
  {
    id: "1",
    name: "price_it_right",
    title: "Price It Right",
    desc:
      "Test your knowledge of prices in this fun game. Can you beat the competition?",
    image: require("assets/img/games/price-it-right.png"),
    lnk: "/games/price_it_right",
    hasLeaderboard: true,
  },
  {
    id: "2",
    name: "aytsoj",
    title: "AYTSOJ",
    desc:
      "Are you the superwoman of Jamshedpur? Play this game and show he world.",
    image: require("assets/img/games/superwoman.png"),
    lnk: "/games/aytsoj",
    hasLeaderboard: true,
  },
  {
    id: "3",
    name: "hungry_runner",
    title: "Hungry Runner",
    desc:
      "Are you the superwoman of Jamshedpur? Play this game and show he world.",
    image: require("assets/img/games/hungry-runner.png"),
    lnk: "/games/hungry_runner",
    hasLeaderboard: true,
  },
  {
    id: "4",
    name: "test",
    title: "Runner Test",
    desc: "Hope the communication works finally :p. Play the game to find out",
    image: require("assets/img/games/endless-runner.png"),
    lnk: "/games/test",
    hasLeaderboard: true,
  },
  {
    id: "5",
    name: "lifeSahiHai",
    title: "Life Sahi Hai",
    desc: "Too much uncertainty? Now hold the reigns to your everyday life in this fun simulation.",
    image: require("assets/img/games/lifeSahiHai.png"),
    lnk: "/games/lifeSahiHai",
    hasLeaderboard: true,
  },
  {
    id: "6",
    name: "hul",
    title: "Lockdown Diaries",
    desc: "Did Lockdown ruin your shopping spree?If yes, don't worry because we have Lockdown Diaries.Come and enjoy a game filled with fun, virtual shopping and many more surprises. Let the spree continue.",
    image: require("assets/img/games/0001.jpg"),
    lnk: "/gamespilot/lockdownDiaries",
    hasLeaderboard: true,
  },
  {
    id: "7",
    name: "itc",
    title: "Gokuldham mein ek din",
    desc: "Come, play and have a fun break in Gokuldham Society. This is a game of chance and choice.",
    image: require("assets/img/games/Poster.png"),
    lnk: "/games/GkdMeEkDin",
    hasLeaderboard: true,
  },
  
];

export default GameInfo;
