import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Component } from "react";
import { PropTypes } from "prop-types";
class AboutUs extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
  
    static get propTypes() {
      return {
        classes: PropTypes.any,
      };
    }
  
    render() {
      const { classes } = this.props;
      return (
        <>
<section className="relative py-20 bg-white">
            
              <div id= "AboutUs"
               className="relative flex flex-col min-w-0 break-words bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 mt-10 mb-10 mx-10 mb-6 shadow-xl rounded-3xl -mt-64">
                <div className="px-6">
                  <div className="text-center mt-12">
                    <h3 className="text-4xl font-semibold leading-normal text-yellow-500 mb-2">
                    About Us
                    </h3>
                    <p className="text-lg text-amber-200">
                    The Marketing Association of XLRI, or MAXI as it is
                        fondly known, is the oldest committee on campus with a
                        fifty year legacy. Continuing the legacy, we are now extending this experience online, through MAXI Games - a fun and interactive way of engaging our audience!
                    </p>

                  </div>
                  
                  

              <div className="container mx-auto px-4 lg:pt-12 lg:pb-32">
              <div className="flex flex-wrap mt-8 justify-center">
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                    <i className="fas fa-medal text-xl"></i>
                  </div>
                  <h6 className="text-xl mt-5 font-semibold text-yellow-500">
                    World Records
                  </h6>
                  <p className="mt-2 mb-4 text-amber-200">
                    An ISO 9001-2000 certified organization, MAXI holds both a
                    Limca and a Guinness World Record.
                  </p>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                    <i className="fas fa-lightbulb text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-yellow-500">
                    A legacy of 50 years
                  </h5>
                  <p className="mt-2 mb-4 text-amber-200">
                    Established way back in 1971, MAXI enjoys unparalled reach
                    on social media platforms.
                  </p>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-amber-400 inline-flex items-center justify-center">
                    <i className="fas fa-handshake text-xl"></i>
                  </div>
                  <h5 className="text-xl mt-5 font-semibold text-yellow-500">
                    Industry Partnerships
                  </h5>
                  <p className="mt-2 mb-4 text-amber-200">
                    Helped launch and improve products for prestigious companies
                    such as Pepsi, Nestle and HUL.
                  </p>
                </div>
              </div>
              <div className="text-center flex-auto text-amber-50 text-4xl font-semibold font-sans pt-5">
              <button type="button" 
                     className="text-indigo-800 bg-gradient-to-bl from-orange-500 via-yellow-300 to-orange-500 animate-gradient-x hover:scale-110 focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800 font-extrabold text-4xl xl:text-5xl rounded-lg px-5 py-2.5 text-center mr-2 mb-5 mt-5 xl:mt-10"
                     onClick={this.loginpopup}
                     >PLAY GAMES</button>
                   </div>
                 </div>
               </div>
              
            </div> {/*About Us Div */}
          </section>
      </>
    );
  }
}

export default AboutUs;