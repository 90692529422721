import React, { Component } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import FirebaseAuth from "../views/auth/FirebaseAuth";
import OTForm from "views/auth/OTForm";
import { userContext } from "utils/userContext";
import { dbCreateOTUser } from "utils/firebaseDB";
import { PropTypes } from "prop-types";
import { backendURL } from "../utils/backendInfo";
//import Ticket from "views/auth/Ticket";
//import { exportComponentAsJPEG } from "utils/exportAsJpg";
//import { isPaymentCompleted } from "utils/authHelper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";

const axios = require("axios");
const { customAlphabet } = require("nanoid");

class OTAuth extends Component {
  static get propTypes() {
    return {
      updateUserContext: PropTypes.any,
      type: PropTypes.any,
    };
  }

  mobile = null;
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onFormFill = (data) => {
    if (this.mobile) {
      data.mobile = this.mobile;
      data.numberOfTickets=0;
      
      for(var i=1; i<=23;i++)
      {
        data[i]=0;
      }
      const alphabet =
        "123456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz";
      const nanoid = customAlphabet(alphabet, 10);
      data.maxiId = "MAXI-" + nanoid(); //=> "dCok8DprjX"
      data.createdAt = moment().format("DD/MM/YYYY , HH:mm:ss");

      this.props.updateUserContext({
        ...data,
        isSignedIn: true,
        isFormFilled: true,
      });
      dbCreateOTUser(data);
      //send data to backend for newly filled form
      axios.post(backendURL + "/OTusers", data).then(function () {});
    }
  };

  
  render() {
   
    return (
      <>
        <Navbar transparent />
        <main>
          <section
            className="relative w-full h-full py-40 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/backgrounds/register_bg_2.png") +
                ")",
            }}
          >
            <userContext.Consumer>
              {(user) => {
                this.mobile = user.mobile;
                return (
                  <div>
                    {!user.isSignedIn ? (
                      <FirebaseAuth />
                    ) : !user.isFormFilled ? (
                      <OTForm onFormFill={this.onFormFill} />
                    ) : 
                      <div className="text-center">
                        <Link to="/fair/ticket/tracking">
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              padding: "1rem 2rem",
                              fontSize: "18px",
                              fontWeight: "bold",
                              margin: "20px",
                            }}
                          >
                            I agree to the terms and conditions 
                          </Button>
                        </Link>
                      </div>
                    }
                  </div>
                );
              }}
            </userContext.Consumer>

            <FooterSmall absolute />
          </section>
        </main>
      </>
    );
  }
}

export default OTAuth;