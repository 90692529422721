import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton
} from "react-share";

import "assets/styles/tailwind.css";

export class SocialShare extends React.Component {
  shareUrl = "https://maxi-xlri.in/gamespilot";
  shareTitle = "Check out this fun online game and stand a chance to win exciting prizes!";
    render() {
      return (
        <div className="text-center my-4">
                      <FacebookShareButton
                        url="https://maxi-xlri.in/gamespilot?utm_source=fb&utm_medium=site_share_button&utm_campaign=pilotrun"
                        quote={this.shareTitle}
                        className="mx-4"
                      >
                        <FacebookIcon size={45} round />
                      </FacebookShareButton>

                      <WhatsappShareButton
                        url="https://maxi-xlri.in/gamespilot?utm_source=wa&utm_medium=site_share_button&utm_campaign=pilotrun"
                        title={this.shareTitle}
                        separator=":: "
                        className="mx-4"
                      >
                        <WhatsappIcon size={45} round />
                      </WhatsappShareButton>

                      <TwitterShareButton
                        url="https://maxi-xlri.in/gamespilot?utm_source=twitter&utm_medium=site_share_button&utm_campaign=pilotrun"
                        title={this.shareTitle}
                        className="mx-4"
                      >
                        <TwitterIcon size={45} round />
                      </TwitterShareButton>

                      <TelegramShareButton url="https://maxi-xlri.in/gamespilot?utm_source=tg&utm_medium=site_share_button&utm_campaign=pilotrun" className="mx-4">
                        <TelegramIcon size={45} round />
                      </TelegramShareButton>
                    </div>
      );
    }
  }