import React from "react";
import Spin from "react-cssfx-loading/lib/Spin";
import "assets/styles/tailwind.css";

export class Spinner extends React.Component {
    render() {
      return (
        <div className="flex h-screen justify-center items-center">
          <Spin color="#3B82F6"/>
        </div>
      );
    }
  }