import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fernet from 'fernet' ;
import { Button } from "@material-ui/core";

// import { GoogleSpreadsheet } from 'google-spreadsheet';

// const SPREADSHEET_ID = "1mhovCnhYoSHslM8xYlwB_D-cQ5RhGLYFzeeTvA68Y_M";
// const SHEET_ID = 0;
// const CLIENT_EMAIL = "ticket-tracking@maxi-xlri.iam.gserviceaccount.com";
// const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDA2h4YTD5MBwjL\nHdn77KnqPfeW6Lxet/F2g2TvqAu0O9ZOgkayn8kotzJjAK23tXdVpiMR2ANHPpix\nSwDFfA/NYt0otYxNSOIk/6Rm227VG4Jl8HeCD6latKFz8awykQcQgKeSO1Ql5N9T\ndgLLFMCGz5VN8XwWC2CYi6wNUA0QL5b7dOMHeUeYF1Wz/m+0jjn8JvxNQI632vV1\nDvGl/ilTNEU051PyGY96LXks8BYFjkjDVYKCa0EapM45KJKfAW13NmfghFJt/WFC\nuwek9TBARjSdOWUAvaNc3j33KT0DNXbl7KXYVhvbfFwAi5Wb6D4fWcM/VW2pBSMz\nbWURaoUPAgMBAAECggEAD7RTZLrPYeU6v+ufj4Z6Cb4Ka6AVODNgzDwD8V4tDHwe\nRgi8KsScnskC/JFBaZHd9LwRjrJEBhO2Cc39SJet48BMGTS7hvmgl3m35hEIHqjd\nqWEid5VrPvHcVJ2Ws7bKrVCEv4GUUdJS+1rDUc1P+5/A3UwvxM3KtnFn99zlIMRT\nGL94wcF0Dc/RyxQa0pdtpm41H57qvPlgFrbVHQbvhqzF5efHvqT0TvUmsKgFL+fv\nKunP2W2sddCOlXU41QThEAJYxS6uOiS3c2t9I5eolqMUrO8NorWUPcbthwMG32rb\nC0gRXE95/S7l3kWtWLWhJGXuhLstNYbeyRCf0+VGRQKBgQDqgJ+vLQxVY7tDvocK\n+7ZFXiuWsLMjfVnBnfA/boz18+o9U8OOERFcExr0OtXRyAbUYk4Zz5vAkUUVxR7R\nDBs0nXZhzZfZ8Z+kM92k371vPKQbF6e8r6d/1m9yW1zhvFKPjCa3XtxEqjFNkfhW\n+8MsuDG0wuP7DVkFOh908/qZqwKBgQDSiAdktMzYJRiCav6AY/WQg3puU3hucI1B\nbVZMyS8nSW7kHkyOFEr3nTTt/XHvwV1h92c6v8TZte2HQLqmb6He+prZyBZRzLVI\n1vv+sjdKIk8XVqccsRfaHb/W7I8EskbjUJLm9x0B1DMgJ/ipDqIpN87Y3Y/i9u/H\nFwiEQ8qGLQKBgEpgNUVoMks68IASA97V6v+hWI3YyVhuP3OLVlGoDIlNrXN6B8Id\nY7YiUSA5MOvyV0gxTbgeArZW3OSEurtu7YoIjy2B+bGkUw/Pa3MJi5mmUB5PqGHO\nDIAdUQeiNplaE4131CfArHgSysRhkbR08XhTi2Bbl9deamegAa4kW801AoGBAIFS\nX8BDs0TeXJTYJmc1f8V6jf0xNtwYNRzIjedy+Y0XdY+Y8QuheX9qyPWb5p4Q2Ezy\nTS3wWHonX7XiVAUis3sl03MNjnBG3+DTdk24qW/XbV/3sYVVX0JzXcEm5J9M53CA\nsQxBJUdXUuRgyUUHoSLSjjP2vYj8iMNx6aBdiA0tAoGBALByhlYvyu9lcNoES8eD\nVBhZ8kTbkRoBn6Bfan2vWJol3/wyUmwh/eJv6xeiubzl1EmmGj9UYDqJOKG/WAfY\nADFUieKjT6DKuUs0XEKTAmJLalODfC7aZBrUVZoN8OKmrxdzweqOQj9g1Mz2Xdnv\nTL2fDq9dwVZrEy9PQVt6BMXw\n-----END PRIVATE KEY-----\n";

// const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

// export async function updateIOS(id){

//   await doc.useServiceAccountAuth({
//     client_email: CLIENT_EMAIL,
//     private_key: PRIVATE_KEY,
//   });
  
//   await doc.loadInfo(); // loads document properties and worksheets
  
//   await doc.loadCells('A1:F501');
//   sleep(4000);
//   console.log(doc.title);
//   console.log("HIT");
    
//   const sheet = doc.sheetsByIndex[1]; 
//   console.log(sheet.title);
//   console.log(sheet.rowCount);

  
//   const row = { ID: id };
//   const result = await sheet.addRow(row);

// }


const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);


class QRScanner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 100,
      result: "null",
      allIDs: [],
    }

    this.handleScan = this.handleScan.bind(this)
  }

  notify = () => toast("QR Captured Successfully!!");
  

  handleScan(data){
    if(data!=null && this.state.result!=data)
    {
        this.setState({result: data});
        this.props.onScan(data.substring(31));
        this.notify();
    }

  }
  handleError(err){
    console.error(err)
  }
  
  
  

  render(){
    const previewStyle = {
      height: 320,
      width: 320,
    }

    return(
      <div class="container">
        <QrReader
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
          />
        <ToastContainer/>
        
      </div>
    )
  }
}

export default QRScanner;