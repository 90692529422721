export var formJson = {
  title: "Get yourself registered to play the games!",
  logoWidth: 60,
  logoHeight: 60,
  showProgressBar: "bottom",
  showQuestionNumbers: "off",
  pages: [
    {
      title: "Tell us about yourself!",
      questions: [
          {
              name: "name",
              type: "text",
              title: "Tell us your name!",
              placeHolder: "FirstName LastName",
              isRequired: true,
            },
    
            {
              isRequired: true,
              name: "gender",
              title: "Select your gender",
              type: "radiogroup",
              choices: [
                {
                  value: "male",
                  text: "Male",
                },
                {
                  value: "female",
                  text: "Female",
                },
                {value: "others",
                 text: "Prefer not to say",
                },
              ],
              colCount: 0,
            },
            // {
            //   startWithNewLine: true,
            //   name: "age",
            //   type: "dropdown",
            //   inputType: "number",
            //   title: "What is your age?",
            //   choices:["< 18","18–24", "25–30", "31–40", "40-50", "50+"],
            //   isRequired: true,
            // },
            {
              startWithNewLine: true,
              name: "age",
              type: "text",
              inputType: "number",
              title: "What is your age?",
              //choices:["< 18","18–24", "25–30", "31–40", "40-50", "50+"],
              isRequired: true,
            },
            {
              startWithNewLine: false,
              name: "contact",
              type: "text",
              inputType: "number",
              title: "Phone No",
              isRequired: true,
            },
    
            {
              name: "email",
              type: "text",
              inputType: "email",
              title: "Enter your email id",
              placeHolder: "example@gmail.com",
              isRequired: true,
              validators: [
                {
                  type: "email",
                },
              ],
            },
        {
          type: "radiogroup",
          name: "education",
          title: "What level of education have you attained?",
          choices: ["High School", "Undergraduate", "Postgraduate"],
          colCount: 0,
          isRequired: true,

    },
  ]},
    {
      title: "You're almost there",
      questions: [
        {
          type: "radiogroup",
          name: "laptop",
          title: "Do you own a laptop/PC?",
          choices: ["Yes", "No"],
          colCount: 0,
          isRequired: true,
        },
        {
          type: "radiogroup",
          name: "4wheeler",
          title: "Do you own a motor vehicle?",
          choices: ["Yes", "No"],
          colCount: 0,
          startWithNewLine: false,
          isRequired: true,
        },

        {
          type: "radiogroup",
          name: "washingmachine",
          title: "Do you own a washing machine?",
          choices: ["Yes", "No"],
          colCount: 0,
          startWithNewLine: true,
          isRequired: true,
        },

        {
          type: "radiogroup",
          name: "land",
          title: "Do you have agricultural land?",
          choices: ["Yes", "No"],
          colCount: 0,
          startWithNewLine: false,
          isRequired: true,
        },

        {
          type: "radiogroup",
          name: "phone8000",
          title: "Does your phone cost more than Rs. 8,000?",
          choices: ["Yes", "No"],
          colCount: 0,
          startWithNewLine: true,
          isRequired: true,
        },
      ],
    },
  ],
};




