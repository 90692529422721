import React from "react";
import CardGame from "components/Cards/CardGame";
import GameInfo from "components/Arrays/GameInfo";
import { userContext } from "utils/userContext";
import AuthWall from "./auth/AuthWallPilot";
import { hasRegistered } from "../utils/authHelper";
import { SocialShare } from "components/Share/SocialShare";
import ReactGA from 'react-ga';
import { PropTypes } from "prop-types";

var GameDisplay = [];
export function listOfGames() {
  var arrOfGames = [];
  var id = 1;
  let game;
  
  game = GameInfo.find((game) => game.name === "hul");
  if (game) {
    game["id"] = id;
    id++;
    arrOfGames.push(game);
  }
  return arrOfGames;
  }
  
export class GamesPilot extends React.Component {

  static get propTypes() {
    return {
      updateUserContext: PropTypes.any,
      eventLocation: PropTypes.any,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false
    };
    this.eventProceed = this.eventProceed.bind(this);
  }

  eventProceed(props) {
    ReactGA.event({
      category: 'User',
      action: props.eventLocation,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.eventProceed('Game card page opened');
  }

  render() {
    return (
      <userContext.Consumer>
        {(user) => {
          GameDisplay = listOfGames();
          return !hasRegistered(user) ? (
            <AuthWall type="registration" />
          ) : (
            <>
              <section className="relative py-16 bg-white"
              >
                <div className="container mx-auto px-4">
                  <div className="relative flex flex-col px-6 min-w-0 break-words bg-gradient-to-r from-slate-900 via-purple-900 to-slate-900 w-full mb-64 h-3/4 shadow-xl rounded-lg mt-1">
                      <div className="text-center mt-5">
                        <h3 className="text-4xl uppercase font-semibold leading-normal text-white mb-2">
                          Let&#39;s play the game!
                        </h3>
                      </div>
                      <div className="py-5 border-t border-gray-300 text-center">
                      </div>

                      <div className="flex flex-wrap flex-col">
                        {GameDisplay.map((info) => {
                          return (
                            <CardGame
                              name={info.name}
                              key={info.title}
                              title={info.title}
                              desc={info.desc}
                              image={info.image}
                              lnk={info.lnk}
                              hasLeaderboard={info.hasLeaderboard}
                            />
                          );
                        })}
                       </div>
                      
                      <div>
                      <h4 className="text-2xl text-center textfont-semibold leading-normal text-white mb-2">
                          Share the game with your friends!
                      </h4>
                      <SocialShare />
                      </div>
                  </div>
                </div>
              </section>
            </>
          );
        }}
      </userContext.Consumer>
    );
  }
}
