import React from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import ReactPlayer from "react-player";
import Carousel from "components/CarouselForFair";
import ContactUs from "components/ContactUs";
import { Button, Box } from "@material-ui/core";
import thumbnail from "assets/img/backgrounds/thumbnail.jpg";
import { Link } from "react-router-dom";
import { Component } from "react";
import SpotifyPlayer from 'react-spotify-player';
import firebase from "../../utils/firebaseConfig";
import "firebase/database";


require("../Home.css");

const size = {
  width: '100%',
  height: '50%',
};
const view = 'list'; // or 'coverart'
const theme = 'black'; // or 'white'

const database = firebase.database();


export default class MaxiFairShortDesc extends Component {

    constructor(props){
        super(props)
        this.state = {
          interestCount: null,
          flag: true
        }
    }

    handleClick = () => {
        this.setState({flag: !this.state.flag});
        if(this.state.flag==true)
        {
            this.setState({interestCount: this.state.interestCount+1 });
            database.ref("interestCount/count").set(this.state.interestCount+1);
        }
        else
        {
            this.setState({interestCount: this.state.interestCount-1 });
            database.ref("interestCount/count").set(this.state.interestCount-1);
        }
        
        
        
      };

      componentDidMount = async () => {

        const ref = database.ref("interestCount");
        
        var snapshot = await ref
          .once("value");
        snapshot.forEach((child) => {
    
            this.setState({interestCount:child.val()});
        });
    
    
        
      }

  
  render() {
    return (
      <>
        {/* <Navbar transparent /> */}
        <main>
        <div class="flex flex-col h-screen">
          <div className="relative pt-16  flex content-center items-center justify-center">
            <div className="absolute top-0 w-full h-full bg-center bg-cover">
              <Carousel />
              <section
            className=" pd-20"
            style={{ backgroundColor: "#ffff" , }}
          >
            <div className="container mx-auto px-4">
              {/* <div className="flex flex-wrap"> */}
                <h1 style={{ fontSize:"150%", marginTop:"4%"}}><b> MAXI FAIR 2023 </b></h1>

                <h1 style={{ fontSize:"70%",}}><i class="fa fa-calendar fa-fw" aria-hidden="true"></i> 21-22 Jan 2023</h1>
                <h1 style={{ fontSize:"70%",}}><i class="fa fa-map-marker fa-fw" aria-hidden="true"></i> XLRI Football Ground</h1>

                <h1 style={{ fontSize:"100%", marginTop:"4%"}}><b> More Info </b></h1>
                <p style={{ fontSize:"70%",}}>A two day extravaganza in the heart of Jamshedpur, MAXI Fair
                    offers fun events and games with assured gifts</p>

                    <h1 style={{ fontSize:"100%", marginTop:"4%", marginBottom:"2%"}}><b> Click on Interested to show your interest </b></h1>
                    <div style={{ display:"inline-block"}}>
                    <img height="10%" width="10%" src={require("assets/img/icons/thumbsUp.png")} style={{ display:"inline-block"}}></img> <b>{this.state.interestCount}</b>
                    

                    <Button style={{ marginLeft:"22%"}} variant="contained" color={this.state.flag ? "white" : "secondary"} onClick={this.handleClick}>
                    Interested?
</Button>
                    
                </div>

                    <p style={{ fontSize:"60%", color:"grey"}}>People have shown interest</p>

                </div>
                <br></br>
                <hr></hr>
                
                <div className="container mx-auto px-4">

                <h1 style={{ fontSize:"100%", marginTop:"4%"}}><b> Artist </b></h1>

                <div style={{ display:"inline-block"}}>
                <p style={{ fontSize:"60%"}}> <img height="30%" width="30%" src={require("assets/img/icons/artist.JPG")} style={{ display:"inline-block", borderRadius: "50%", float: "left", marginRight:"5%"}}></img> Get ready to groove to the amazing beats of Shahid Mallya! Look forward to a night of great music, because he is here to sing your favourite songs!
                <br></br><b>22nd Jan, 7:30pm onwards</b></p>

                <br></br>

               
                    
                </div>

                

                </div>
                <br></br>
                <div className="container mx-auto px-4">
                <SpotifyPlayer
  uri="spotify:playlist:37i9dQZF1DZ06evO2Ngeis"
  size={size}
  view={view}
  theme={theme}
/>

                <h1 style={{ fontSize:"100%", marginTop:"4%"}}><b> Witness MAXI Fair </b></h1>
                
              <div className="flex flex-wrap justify-center text-center mb-2">
                <div className="w-full lg:w-6/12 px-4">
                  
                </div>
                <div className="w-full lg:w-10/12 px-4 flex flex-wrap justify-center text-center mb-2">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=sS6d0NLW1Ls"
                    playing
                    controls
                    light={thumbnail}
                  />
                  <div className="w-full lg:w-6/12 px-4 m-4 item-center">
                    <Link to={"/fair"}>
                      <Button
                        variant="contained"
                        style={{
                          padding: "1rem 2rem",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          backgroundColor: "#2d3748",
                        }}
                      >
                        Know more
                      </Button>
                    </Link>
                    <br></br>
                    <br></br>
                    
                    <br></br>
                  </div>
                </div>
              </div>
            </div>

          </section>
          
         
          
      </div>
          {/* <Footer /> */}
          <footer className="footer">
          <Box
  display="flex"
  justifyContent="flex-end"
  alignItems="flex-end"
  
>
          <a href="https://acad.xlri.ac.in/maxi-fair/"><Button
                        variant="contained"
                        style={{
                          padding: "1rem 1rem",
                          fontSize: "16px",
                          color: "white",
                          backgroundColor: "#ed2939",
                        }}
                        
                      >
                        Buy Tickets
                      </Button> </a>
                      </Box>
  </footer>
            </div>
          </div>

          

         
        </main>
      </>
    );
  }
}
