import React, { Component } from "react";
import { Dialog,} from '@headlessui/react'
import FirebaseAuth from "views/auth/FirebaseAuth";
import { PropTypes } from "prop-types";

class AuthModal extends React.Component {

    static propTypes = {
        btnState: PropTypes.func,
      }

    render(){
        return(
        <div>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={this.props.btnState}
            open={true}
          >
        <div className="min-h-screen px-4 text-center"> 
                <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-50" />
                <FirebaseAuth />
        </div> 
              {/* This element is to trick the browser into centering the modal contents. */}
             
            
          </Dialog>
       </div>

        )
    }
}

export default AuthModal;