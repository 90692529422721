import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyD5rIHNiTizk0sUmA_Z9IMrT8o6DKa8ojg",
  authDomain: "maxi-xlri.firebaseapp.com",
  databaseURL: "https://maxi-xlri.firebaseio.com",
  projectId: "maxi-xlri",
  storageBucket: "maxi-xlri.appspot.com",
  messagingSenderId: "798704015894",
  appId: "1:798704015894:web:eef0cde9d70308a03a7337",
  measurementId: "G-GLVRCZCDNE",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
