import React, { Component } from "react";
import QRScanner from "./QRScanner";
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { hasRegistered } from "./../utils/authHelper";
import OTAuth from "layouts/OTAuth";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { userContext } from "utils/userContext";
import Typography from "@material-ui/core/Typography";
import firebase from "./../utils/firebaseConfig";
import "firebase/database";
import AuthNavbarOT from "components/Navbars/AuthNavbarOT";
import TicketLeaderboard from "./TicketLeaderboard";

const SPREADSHEET_ID = "1mayrgWoYJU_VleKNyPFD5NgXM3XDVIOcFEf8ZPdj4u8";
const SHEET_ID = "859516168";
const CLIENT_EMAIL = "id-024-maxi-fair-tracking-tick@maxi-xlri.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC5j5+hisx6SA47\nJg3klzLaw1kJdWfqDhapFBq20dYFFTf+w4q93KQ3krG63labrSdT56CUnSzyQ4WZ\nN3k93yQpAErT59vQFLaOzbvBa5VHV1zE1pK0BLXFnmWVepdetSJr1dHjU2ewnclw\n4RkqiYdjaAgMMhGnPoI1sR1pMoGzodaGLEhZdNtqs6bhAQL3ZLjoVjNx4Q1dppfj\nOZ1XnB6L9E4p5+IhLFtElb9nFsFtQIC40L2lt6U5zJFPUKsbsseZOG38mcF8A/yx\nXQy731YQdgfwjv7Mr+FefQOyGeNvodBRrVZhyMoe9TPYLhSYN59HjyRcONJDSh5V\nfHAHEL9nAgMBAAECggEAIyeSlog1NPVxvML43tXGTKu1spULmswQfvfQF9KpOU1L\nzng9ULr8YYrpIP3+an/jc4HuajlcyokARoa78XzU3O+xPXQvsLw72dIgQAShw2JJ\nsQD1yiUaStY3aJpREyA95TAdE3Q5sLwz7a63ldd4TtzmqWjS71xoTsO/Tq7C48Ty\njInq8QZAr+HbQrVmW/I/Ih2uTpAgGSEyRUTXhmfsKbN16r8ZDZ7jPcQGXJAGI+jD\nx4jaeO2wXUECMhA7D/PJSgoqSWhZ7l+48kjUjLWNrjGkzT/OaOLuIhEP7oU0yiTr\n4hk/HX4zrCUv9fvijKrImxrxXjha35rKoVQr66+EAQKBgQDrOBQiS0BU0O4rDIqV\nfrnd+X/1SHPhkQ4IHdcreoPn7V8CiOx6B2w4Axs+8kMs+k3ZUhW9HR1TZFeUvsUj\n35V90KZuZQanlABIkDnP7e97oin04LfdX6MuDOBtvcgPAoapPgkGssK9ysgaPY/i\nISGqfNB2AR5XxBqzoWXUDp8udQKBgQDJ9G9qx0CyWbgO3q0A6FM0PrzVAI0y3blQ\n9RKtA1+/fkLUPj1OxCS9DoZLAD3APoRPUAl55iAQ8socgFR+p1rJ1AukIxENj7ui\nmoDJjmNuzqQYXPDzuJGBEkm9jPS7CZtbtyo5cQ7ubLxbBeuMBVOJyLE6X4WB65rA\njoE5AR1y6wKBgHK5xnA3negAg7nxgwKJbeSLldKwBP1G/74Lt/AX8JBFoaqd2CFo\nz2q+cSKRjRw59ZCCzeGQesY60dDV/1eQ7NwR0atUkRtZP44k8l2tYro9jRNIK8XI\nqNGELE66WwKfK8kOXd6rf/qMFm9eCXi0yjPabt6vivopxxBIL19vxkolAoGBAKcQ\nzcbQdGXWMJwke4u/pW0X5VBzt1r37yDYzA1SODgKtDAuGLt7KUU4qcGLvom1bfDK\nrjwoMiizIJ15T+zCdyl1f/fVhnroh3lZROb4Iqt8yGTbJf0Il13mChiDbcT4iovR\nt11zeL2mOB84g4X+tf/KhUpb0O8MP6XBfYbxtDHnAoGAGuNoRwcjBqwFujnfnHHC\nxwaf+Oo4QZR58Jx+oGgIHQpJie88xZBw5G42imap9Cifh1rPE/vM/iXbTiVF/IVu\nkzr3wdtIHb8xNcFPfOHLmIhkinEfZzPPSaIxoSKNo80er+ITLqS8Z6DV0wQIh5D3\nbm9QplB63bQObAUQHhThTsg=\n-----END PRIVATE KEY-----\n";
const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

const database = firebase.database();

const updateTicketStatus = async (id) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo(); // loads document properties and worksheets
    await doc.loadCells('A1:E1001');
    sleep(4000);
    console.log(doc.title);
    
    const sheet = doc.sheetsByIndex[0]; 
    console.log(sheet.title);
    console.log(sheet.rowCount);

    // const d = new Date();
    // let minutes = d.getMinutes();
    // if(minutes<10)
    // {
    //   minutes="0"+minutes;
    // }
    // let hour = d.getHours();
    // let time= hour+ ":" +minutes;

    for(var i=1;i<=sheet.rowCount;i++)
    {
    if(id===sheet.getCell(i,0).value)
    {
        sheet.getCell(i,4).value = "SOLD";
        sheet.getCell(i,4).textFormat = { bold: true };
        
        await sheet.saveUpdatedCells(); // saves both cells in one API call
        console.log("UPDATED STATUS");
        break;
    }

    }
            
  } catch (e) {
    console.error('Error: ', e);
  }
};

const updateIDs = async (id,mobile,numberOfTickets,todaySales,name) => {
  
  let sales_count = 1
    if (id.includes("V1-"))
    {
      sales_count = 4
    }
    else if (id.includes("V2-"))
    {
      sales_count = 2
    }


    database.ref("OTusers/"+mobile+"/numberOfTickets").set(numberOfTickets+sales_count);
    database.ref("OTusers/"+mobile+"/"+todayDate).set(todaySales+sales_count);

    
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });

    
    await doc.loadInfo(); // loads document properties and worksheets
    
    
    const sheet = doc.sheetsByIndex[4]; 
    console.log(sheet.title);
    console.log(sheet.rowCount);

  //   const d = new Date();
  //   let minutes = d.getMinutes();
  //   if(minutes<10)
  //   {
  //     minutes="0"+minutes;
  //   }
  //   let hour = d.getHours();
  //   let time= hour+ ":" +minutes;

    const row = { TicketID: id, Status: "SOLD", SoldBy:name};
    const result = await sheet.addRow(row);
      
  } catch (e) {
    console.error('Error: ', e);
    //alert("Error! Scan again please.")
  }
};


const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const d = new Date();
const todayDate=d.getDate();




class TicketTracker extends Component {
    constructor(props){
      super(props)
      this.state = {
       ticketIDs: [],
      }
      
    }

    

    handleID = (ticketID,string) => {
      updateTicketStatus(ticketID);
      updateIDs(ticketID, this.mobile,this.numberOfTickets,this.todaySales,this.userName);
    }

   
    render(){
      
  
      return(
        <userContext.Consumer>
          {(user) => {
          this.numberOfTickets = user.numberOfTickets;
          this.mobile = user.mobile;
          this.userName = user.name;
          this.todaySales = user[todayDate];
          return !hasRegistered(user) ? (
            <div className="text-center">
                        <Link to="/OTAuth">
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              padding: "1rem 2rem",
                              fontSize: "18px",
                              fontWeight: "bold",
                              margin: "20px",
                            }}
                          >
                            Log In To Continue
                          </Button>
                        </Link>
                        </div>
          ) : (
            <>
            <AuthNavbarOT btnState={this.loginpopup}/>
            <div class="flex flex-col justify-start items-center mt-16">
             
            <div class="flex-auto mt-4">
              <table class="table-auto subpixel-antialiased border-spacing-2 m-2">
                <tbody>
                  <tr>
                    <td>
                    <Typography variant="subtitle1" gutterBottom>
                          Name: 
                  </Typography>
                  </td>
                    <td>
                    <Typography variant="subtitle1" component="h2">
                          {user.name}
                    </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                  <Typography variant="subtitle1" component="h2">
                          Total Tickets Sold:
                  </Typography>
                  </td>
                    <td>
                    <p class="text-3xl font-bold text-center">
                          {user.numberOfTickets}
                  </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                  <Typography variant="subtitle1" component="h2">
                  Tickets Sold Today:
                  </Typography>
                  </td>
                    <td>
                    <p class="text-3xl font-bold text-center">
                          {user[todayDate]}
                    </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> 
            <div class="flex-auto overflow-hidden mt-5 gap-y-4 bg-white border border-gray-200 rounded-lg drop-shadow-lg">
              <QRScanner onScan={this.handleID}/>
            </div>
             <div class="flex-auto m-2 gap-y-4 bg-white border border-gray-200 rounded-lg drop-shadow-lg">
              <TicketLeaderboard/>
            </div> 
            
            </div>
            </>
        )
      }}

    </userContext.Consumer>
      )
    }
  }
  
  export default TicketTracker;