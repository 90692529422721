//SAMPLE USER INFO OBJECT

// createdAt: "1/17/2021, 10:17:11 AM"
// maxiId: "MAXI-xydR2MPBBB"

// name: "Anugrah Agnihotri"
// mobile: "+918853941111"
// email: "bj20000@astra.xlri.ac.in"
// age: 25
// gender: "male"
// city: "Jamshedpur"

// languages: (2) ["Hindi", "English"]
// education: "Postgraduate"
// employment: "Student"
// maritalStatus: "Single"

// 2wheeler: "No"
// 4wheeler: "Yes"
// airconditioner: "Yes"
// fridge: "Yes"
// washingmachine: "Yes"
// dryersTrimmers: "Yes"
// phone8000: "Yes"
// laptop: "Yes"
// lpg: "Yes"
// land: "No"
import firebase from "./../utils/firebaseConfig";
import "firebase/database";
import { getDatabase, ref } from "firebase/database";
import { sortAndDeduplicateDiagnostics } from "typescript";
const database = firebase.database();
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function numberOfDurables(user) {
  var items = 3; //electricity, fan, tv
  if (user.lpg === "Yes") {
    items++;
  }
  if (user["2wheeler"] === "Yes") {
    items++;
  }
  if (user.fridge === "Yes") {
    items++;
  }
  if (user.washingmachine === "Yes") {
    items++;
  }
  if (user.laptop === "Yes") {
    items++;
  }
  if (user["4wheeler"] === "Yes") {
    items++;
  }
  if (user.airconditioner === "Yes") {
    items++;
  }
  if (user.land === "Yes") {
    items++;
  }
  return items;
}

function classificationForUser(user) {
  const items = numberOfDurables(user);
  const education = user.education;
  var section = "Z1";
  if (education === "High School") {
    switch (items) {
      case 0:
        section = "E2";
        break;
      case 1:
        section = "E1";
        break;
      case 2:
        section = "D2";
        break;
      case 3:
        section = "D1";
        break;
      case 4:
        section = "C1";
        break;
      case 5:
        section = "B2";
        break;
      case 6:
        section = "B1";
        break;
      case 7:
        section = "A3";
        break;
      case 8:
        section = "A3";
        break;
      case 9:
        section = "A2";
        break;
      case 10:
        section = "A2";
        break;
      case 11:
        section = "A2";
        break;
    }
  } else if (education === "Undergraduate" || education === "Postgraduate") {
    switch (items) {
      case 0:
        section = "D2";
        break;
      case 1:
        section = "D2";
        break;
      case 2:
        section = "D1";
        break;
      case 3:
        section = "C2";
        break;
      case 4:
        section = "B2";
        break;
      case 5:
        section = "B1";
        break;
      case 6:
        section = "A3";
        break;
      case 7:
        section = "A2";
        break;
      case 8:
        section = "A2";
        break;
      case 9:
        section = "A1";
        break;
      case 10:
        section = "A1";
        break;
      case 11:
        section = "A1";
        break;
    }
  }
  return section;
}

function isSectionA(user) {
  const section = classificationForUser(user);
  return section.startsWith("A");
}

function isSectionB(user) {
  const section = classificationForUser(user);
  return section.startsWith("B");
}

function isSectionC(user) {
  const section = classificationForUser(user);
  return section.startsWith("C");
}

export function isEligibleForBata(user) {
  return (
    user.age >= 20 && user.age <= 35 && (isSectionA(user) || isSectionB(user))
  );
}

export function isEligibleForZee(user) {
  return (
    user.age >= 20 &&
    user.age <= 55 &&
    user.gender == "female" &&
    (isSectionA(user) || isSectionB(user) || isSectionC(user))
  );
}

async function isEligibleForIcici(user) {
  return(isSectionA(user)&&user.age>=20&&user.age<=40);
}

export function isEligibleForPepsico(user) {
  return user.age >= 16 && user.age <= 24;
}

export function isEligibleForPerfetti(user) {
  return (
    user.age >= 11 && user.age <= 19 && (isSectionA(user) || isSectionB(user))
  );
}
//icici, perfetti, png, bata, zee, pepsico

export function listOfEligibleGamesForUser(user) {
  var retArray = [];
  if (isEligibleForBata(user)) {
    retArray.push("Bata");
  }
  if (isEligibleForZee(user)) {
    retArray.push("Zee");
  }
  if (isEligibleForIcici(user)) {
    //retArray.push(database.ref("icici/range4_male"));
    retArray.push("Icici");
  }
  if (isEligibleForPepsico(user)) {
    retArray.push("Pepsico");
  }
  if (isEligibleForPerfetti(user)) {
    retArray.push("Perfetti");
  }
return retArray; 
}
