import React, { Component } from "react";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";
import FirebaseAuth from "../views/auth/FirebaseAuth";
import PerfettiForm from "views/auth/PerfettiForm";
import { userContext } from "utils/userContext";
import { dbCreatePerfettiUser, dbCreateUser } from "utils/firebaseDB";
import { PropTypes } from "prop-types";
import { backendURL } from "../utils/backendInfo";
import Ticket from "views/auth/Ticket";
import { exportComponentAsJPEG } from "utils/exportAsJpg";
import { isPaymentCompleted } from "utils/authHelper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import QRScanner from "components/QRScanner";
import moment from "moment";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
const axios = require("axios");
const { customAlphabet } = require("nanoid");

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);



class PerfettiAuth extends Component {

  constructor(props){
    super(props);
    this.state = {
     ticketID: null,
     games: null,
     showForm : true,
     clicked: false,
     rollNo: null,
    }
    this.handleFormToggle = this.handleFormToggle.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
  }
  static get propTypes() {
    return {
      updateUserContext: PropTypes.any,
      type: PropTypes.any,
    };
  }

  handleFormToggle() {
    this.setState({showForm: false});
  }

  mobile = null;
  ticketRef = React.createRef();
  shareUrl = "https://maxi-xlri.in/fairTicket";
  shareTitle = "Join me online at the 42nd MAXI Fair on Jan 30-31 2021!";
  

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onFormFill = (data) => {
    if (this.mobile) {
      
      data.mobile = this.mobile;
      const alphabet =
        "123456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz";
      const nanoid = customAlphabet(alphabet, 10);
      data.maxiId = "MAXI-" + nanoid(); //=> "dCok8DprjX"
      data.createdAt = moment().format("DD/MM/YYYY , HH:mm:ss");

      this.handleFormToggle();
      

      this.props.updateUserContext({
        ...data,
        isSignedIn: true,
        isFormFilled: false,
      });
      
      dbCreatePerfettiUser(data,this.state.ticketID);
      sleep(5000);
      
      axios.post(backendURL + "/users", data).then(function () {});
      sleep(4000);
      window.location.href = 'https://maxi-xlri.in/gamesDisplay/'+this.state.ticketID+"/"+null+"/"+data.name.toString()+"/"+data.gender+"/"+null+"/"+data.age;
    }
  };

  handleID = (ticketID,string) => {
      this.setState({ticketID:ticketID});
  }

  handleButtonClick= () => {
    this.setState({clicked:true});
    var roll = this.state.rollNo;
    if(this.state.ticketID == null)
      {
        this.setState({ticketID:roll});
      }
  }

  handleChange(event) {
    this.setState({rollNo: event.target.value});
  }

  onSaveTicket = () => {
    window.scrollTo(0, 0);
    exportComponentAsJPEG(this.ticketRef, { fileName: "MaxiFairTicket.jpg" });
  };

  render() {
    const shareButtons = (
      <div className="text-center my-4">
        <FacebookShareButton
          url={this.shareUrl}
          quote={this.shareTitle}
          className="mx-4"
        >
          <FacebookIcon size={45} round />
        </FacebookShareButton>

        <WhatsappShareButton
          url={this.shareUrl}
          title={this.shareTitle}
          separator=":: "
          className="mx-4"
        >
          <WhatsappIcon size={45} round />
        </WhatsappShareButton>

        <TwitterShareButton
          url={this.shareUrl}
          title={this.shareTitle}
          className="mx-4"
        >
          <TwitterIcon size={45} round />
        </TwitterShareButton>

        <LinkedinShareButton url={this.shareUrl} className="mx-4">
          <LinkedinIcon size={45} round />
        </LinkedinShareButton>
      </div>
    );

    return (
      <>
        <Navbar transparent />
        <main>
          <section
            className="relative w-full h-full py-40 min-h-screen  bg-gray-900 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/backgrounds/register_bg_2.png") +
                ")",
            }}
          >
            <userContext.Consumer>
              {(user) => {
                this.mobile = user.mobile;
                return (
                  <div>
                    {!user.isSignedIn ? (
                      <FirebaseAuth />
                    ) :  (
                      !this.state.clicked ? 
                      <div class="flex flex-col justify-start items-center mt-16">
                        <div class="flex-auto overflow-hidden mt-5 gap-y-4 bg-white border border-gray-200 rounded-lg drop-shadow-lg">
                      
                          <label>
                        Ticket ID:
                        <input type="text" value={this.state.rollNo} onChange={this.handleChange} />
                      </label>
              <b>OR</b>
                        <p>Scan the QR on your Ticket. After successful scan, click on Next</p>
                        <QRScanner onScan={this.handleID}/>
                        <Button onClick={this.handleButtonClick}>Next</Button>
</div>
                      </div> :
                      <div class="flex-auto overflow-hidden mt-5 gap-y-4 bg-white border border-gray-200 rounded-lg drop-shadow-lg">
                          <PerfettiForm onFormFill={this.onFormFill} />  
                          <p>{this.state.showForm}</p>        
                          
                          
                      </div>
                    )
                    
                    
                
                } 
                  </div>
                    ); 
              }}
              
            </userContext.Consumer>

            <FooterSmall absolute />
          </section>
        </main>
      </>
    );
  }
}

export default PerfettiAuth;